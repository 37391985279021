import React from 'react';
import PropTypes from 'prop-types';

import { StyledInputField, ErrorMessage } from './styles';

/**
 * Represents an input field with label.
 * @param {string} name required
 * @param {string} label required
 * @param {string} type required
 * @param {boolean} isRequired required
 * @param {boolean} isInvalid required
 * @param {function} onChange required
 * @param {string} value required
 * @param {string} fieldErrorText required
 */
const InputField = ({
  name,
  label,
  type,
  value,
  isRequired,
  onChange,
  isInvalid,
  fieldErrorText,
  placeholder,
}) => (
  <>
    <StyledInputField htmlFor={name}>
      {label}
      {isRequired && `*`}
      <input
        id={name}
        name={name}
        type={type}
        value={value}
        required={isRequired}
        aria-required={isRequired}
        aria-invalid={isInvalid}
        onChange={onChange}
        className={isInvalid ? 'invalid' : ''}
        placeholder={placeholder || ''}
      />
    </StyledInputField>
    <ErrorMessage aria-live="polite">
      {isInvalid && fieldErrorText}
    </ErrorMessage>
  </>
);

InputField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isRequired: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  isInvalid: PropTypes.bool.isRequired,
  fieldErrorText: PropTypes.string,
  placeholder: PropTypes.string,
};

InputField.defaultProps = {
  placeholder: '',
  fieldErrorText: '',
};

export default InputField;
