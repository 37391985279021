import React from 'react';
import PropTypes from 'prop-types';

import LinkGroup from '../core/LinkGroup';

import { StyledPageNavigation } from './styles';

const PageNavigation = ({ components, headingLevel }) => {
  const { ariaLabel, title } = components[0];
  console.log('IR MENU', components)
  return (
    <StyledPageNavigation className="styledPageNavigation">  
      <LinkGroup
        ariaLabel={ariaLabel}
        title={title || ''}
        links={components[0].listComponents}
        titleVisible
        displayStyle="inline-with-title"
        className="pageNavigation"
           />
    </StyledPageNavigation>
  );
};

PageNavigation.propTypes = {
  components: PropTypes.array.isRequired,
};

export default PageNavigation;
