import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import RichText from '../RichText';
import { LocaleProvider } from '../Context/LocaleProvider';
import Link from '../Link';

import Image from '../Image';
import { theme } from '../../../themes/themes';
import { getLinkUrl } from '../../../utils/helpers';
import FlexSpacer from '../FlexSpacer';
import HeadingElement from '../HeadingElement';

import { StyledCard, Info, AbsoluteRatio, StyledImage } from './styles';

/**
 * Represents an Image.
 * @param {string} title required title
 * @param {string} dateTime [optional=null] - represents date and time
 * @param {string} description required description
 * @param {array} links [optional=[]] - represents links on the card
 * @param {string} image required - image
 * @param {string} bkgColour [optional="white"] - background color
 */
const Card = ({
  id,
  title,
  dateTime,
  description,
  links,
  linksTest,
  image,
  imageBackground,
  bkgColour,
  cardType,
  className,
  displayDevice,
  isRich,
  content,
  onClick,
  columns,
  componentStyle,
  ctaStyle,
  ctaStyleTest,
  headingLevel,
}) => {
  const headingElement = (bkgColour =
    bkgColour === 'default' ? 'white' : bkgColour || 'white');
  const locale = useContext(LocaleProvider);
  const getCtaStyle = (index) => {
    const ctaStyleDefine =
      ctaStyleTest && index && ctaStyleTest.length > 0
        ? ctaStyleTest[index]
        : ctaStyle;
    const imageBehind = className && className.includes('image-behind-text');
    if (ctaStyleDefine === 'text-link') {
      return 'card-link';
    } else if (
      ((cardType && cardType.includes('pattern-card')) ||
        bkgColour === 'default' ||
        bkgColour === 'white' ||
        bkgColour === 'transparent' ||
        bkgColour === null) &&
      (!imageBehind || (imageBehind && !image && !imageBackground))
    ) {
      return 'button defaultButton solid-on-dark-bg hvr-bounce-to-right';
    }
    return 'button defaultButton hvr-bounce-to-right';
  };

  const newTitle =
    isRich && title ? (
      <RichText richTextContent={JSON.parse(title)} />
    ) : title ? (
      <HeadingElement headingLevel={headingLevel}>{title}</HeadingElement>
    ) : null;

  const newDescription =
    isRich && description ? (
      <RichText richTextContent={(typeof description === "object") ? description:JSON.parse(description)} />
    ) : (
      <p>{description}</p>
    );

  const linkBlockDefine = linksTest && linksTest.length > 0 ? linksTest : links;
  const linkBlock =
    linkBlockDefine &&
    linkBlockDefine.map((link, linkIndex) => {
      if (ctaStyleTest) {
        console.log(link, ctaStyleTest[linkIndex]);
      }
      //const url = link.internalLink?.slug || link.externalLink;
      const url = getLinkUrl(link, locale);
      
      if (url) {
        return (
          <Link
            key={link.id}
            ariaLabel={link.ariaLabel}
            children={link.textLink}
            to={url}
            className={`inline ${getCtaStyle(linkIndex)}`}
          />
        );
      }
      return null;
    });

  const borderStyle = bkgColour === 'white' ? 'defaultBorder' : 'no-border';
  // cardType === 'pattern-card'
  //   ? 'no-border'
  //   : bkgColour == 'transparent'
  //   ? ''
  //   : 'defaultBorder';
  const createMarkup = () => {
    return { __html: content.rawText };
  };

  let cardImage;
  if (image) {
    cardImage = (
      <AbsoluteRatio className="absoluteRatio">
        <Image src={image.file.url} alt="" imageSharp={image} />
      </AbsoluteRatio>
    );
  } else if (imageBackground) {
    // used in "Contact Us" locations section
    cardImage = (
      <AbsoluteRatio className="absoluteRatio">
        <Image alt="" imageSharp={image} />
      </AbsoluteRatio>
    );
  }
  return (
    <StyledCard
      className={`card ${
        displayDevice === 'Desktop' || displayDevice === 'Mobile'
          ? `only${displayDevice} `
          : ''
      }${borderStyle} ${
        componentStyle === 'factoid' ||
        componentStyle === 'showcase-link' ||
        componentStyle === 'pull-quote' ||
        componentStyle === 'default'
          ? componentStyle
          : ''
      } ${cardType} ${className || ''}
       ${`cardColor_${bkgColour}`} ${
        image || imageBackground ? 'hasImage' : 'noImage'
      }`}
      backgroundColor={theme.colors[bkgColour]}
      columns={columns || null}
    >
      {cardImage}
      <Info className="card-details-block">
        <div className="card-details">
          {dateTime && <p className="dateTime">{dateTime}</p>}
          {newTitle}
          {newDescription && newDescription}
          {content && (
            <div
              className="specialValue"
              dangerouslySetInnerHTML={createMarkup()}
            />
          )}
        </div>
        <FlexSpacer />
        {links && links.length > 0 && (
          <div className="cardLinkBlock">{linkBlock}</div>
        )}
      </Info>
    </StyledCard>
  );
};

Card.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  isRich: PropTypes.bool,
  dateTime: PropTypes.string,
  links: PropTypes.array,
  image: PropTypes.object,
  bkgColour: PropTypes.string,
  cardType: PropTypes.string,
};

Card.defaultProps = {
  links: [],
  dateTime: null,
  bkgColour: 'white',
  image: '',
  cardType: '',
  isRich: false,
};

export default Card;
