import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

import Banner from '../../components/Banner';

export const SectionDescription = styled.section`
  display: flex;
  margin-top: 5rem;
  margin-bottom: 5rem;
  * {
    font-family: ${themeGet('fonts.sans')};
    font-size: 1.875rem;
    line-height: 2.8125rem;
    font-weight: 600;
  }
  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    margin-top: 3.0625rem;
    margin-bottom: 3.0625rem;
    * {
      font-size: 1.125rem;
      line-height: 1.75rem;
    }
  }
`;

export const DescriptionWrapper = styled.div`
  text-align: center;
  display: inline-block;
  max-width: 64%;
  width: 100%;
  margin: 50px auto 20px;
  font-size: 1.875rem;
  line-height: 2.8125rem;

  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    max-width: 90%;
    text-align: left;
  }
`;
export const CoreBanner = styled(Banner)`
  .backgroundVideo {
    height: unset !important;
    & > div {
      width: 100% !important;
      min-height: 603px;
      @media only screen and (max-width: Calc(1600px + 5rem)) {
        min-height: Calc(Calc(67vw - 5rem) * 0.5625);
      }
      iframe {
        height: 100%;
        @media only screen and (max-width: Calc(1600px - 64px)) {
        }
      }
    }
  }
  &.bannerLeft {
    .backgroundVideo {
      & > div {
        margin-left: 0;
        width: 100% !important;
      }
    }
  }
  .bannerBodyWrapper {
    width: 33% !important;
    max-width: 33% !important;
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &:last-child {
      margin-bottom: 0;
    }

    @media only screen and (max-width: ${themeGet('breakpoints.lg')}) {
      width: unset !important;
      max-width: unset !important;
    }
  }
  .spinmaster-background-wrapper {
    align-items: stretch !important;
    height: unset !important;
    min-height: unset !important;
    & > .onlyDesktop,
    & > .backgroundVideo {
      width: 67% !important;
      height: unset !important;
      min-height: unset !important;
      @media only screen and (max-width: ${themeGet('breakpoints.lg')}) {
        width: unset !important;
      }
    }

    & > .onlyDesktop {
      .backgroundImage {
        min-height: 100%;
        height: 100%;
        img {
          object-position: center center !important;
        }
      }
    }
  }
  @media only screen and (max-width: ${themeGet('breakpoints.lg')}) {
    .spinmaster-background-wrapper {
      display: block !important;
      .backgroundVideo > div iframe,
      .backgroundVideo > div {
        min-height: 56.25vw !important;
      }
    }
    .styledLinkList {
      a {
        margin-right: 1rem;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    .mobileWrapper,
    .gatsby-image-wrapper {
      overflow: hidden;
    }
  }

  // h1 {
  //   font-size: 1.3125rem;
  //   line-height: 1.875rem;
  // }
  // p {
  //   font-size: 0.875rem;
  //   line-height: 1.5rem;
  // }
`;
