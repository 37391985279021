import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const CardsWrapper = styled.section`
  display: -ms-grid;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: ${themeGet('document.gapColumn')};
  grid-row-gap: ${themeGet('document.gapColumn')};
  -ms-grid-columns: 1fr ${themeGet('document.gapColumn')} 1fr
    ${themeGet('document.gapColumn')} 1fr;

  & .card:nth-child(3n + 0) {
    -ms-grid-column: 1;
  }
  & .card:nth-child(3n + 1) {
    -ms-grid-column: 3;
  }
  & .card:nth-child(3n + 2) {
    -ms-grid-column: 5;
  }
  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
  }
  &.accordion,
  &.rolodex {
    grid-row-gap: 0px;
    grid-template-columns: repeat(1, 1fr);
    padding-left: 28px;
    @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
      padding-left: 0;
    }
  }

  &.horizontal-scroll {
    grid-auto-flow: column;
    overflow-x: auto;
    @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
      padding-bottom: 2.4375rem;

      &::-webkit-scrollbar {
        -webkit-appearance: none;
      }

      &::-webkit-scrollbar:horizontal {
        height: 11px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: 4px solid white; /* should match background, can't be transparent */
        background-color: ${themeGet('colors.spinDark')};
      }
    }
    div {
      //changes Card width
      min-width: 300px;
      max-width: 441px;

      @media only screen and (max-width: ${themeGet('breakpoints.xs')}) {
        min-width: 80vw;
      }
      &.card-details {
        min-width: auto;
      }
    }
  }

  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    .card-details {
      margin-bottom: 1.5rem;
      p {
      }
    }
  }
  &.sectionColumns_2.hasPullQuote {
    grid-column-gap: 0;
    .card {
      max-width: 100%;
    }
  }
  @media only screen and (min-width: ${themeGet('breakpoints.md')}) {
    display: -ms-grid;
    display: grid;
    /* grid-template-columns: repeat(3, 1fr); */
    grid-template-columns: ${(props) => `repeat(${props.columns}, 1fr)`};
    &.horizontal-scroll {
      div {
        //changes Card width
        max-width: 100%;
      }
    }
  }

  @media only screen and (min-width: ${themeGet('breakpoints.xl')}) {
    max-width: ${themeGet('document.maxWidth')};
    display: -ms-grid;
    display: grid;
    grid-template-columns: ${(props) => `repeat(${props.columns}, 1fr)`};
    &.horizontal-scroll {
      div {
        //changes Card width ok
        max-width: 100%;
      }
    }
  }
`;

export const StyledWrapper = styled.section`
  border-radius: 5px;
  background-color: ${themeGet('colors.spinSecondaryFour')};
  margin-bottom: 15px;
  padding: 25px;

  nav,
  h2,
  h3 {
    padding-left: 0;
  }
  h2 {
    font-family: Rubik;
    font-size: 1.875rem;
    font-weight: 900;
    letter-spacing: 0.21px;
  }
  a {
    color: ${themeGet('colors.spinDark')};
    font-size: 0.9375rem;
    line-height: 1.25rem;
    text-decoration: none;
    font-weight: 400;
  }

  &.reports {
    padding: 0;
    margin-bottom: 40px;
    & > h2,
    & > h3 {
    }
    & > h2 {
    }

    .groupsListWrapper {
      padding: 0 2rem 2rem;
      column-gap: 2rem;
    }
    @media only screen and (max-width: ${themeGet('breakpoints.sm')}) {
      .groupsListWrapper {
        padding: 0;
      }
    }
    @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
      padding: 25px 13px;
    }

    h2.title,
    h3.title {
      margin: 0;
      font-family: ${themeGet('fonts.rubik')};
      font-size: 1.875rem;
      font-weight: 900;
      padding: 1rem 2rem;
      background-color: ${themeGet('colors.spinDark')};
      color: white;
      border-radius: 5px 5px 0 0;
      margin-bottom: 1rem;
      @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
        ${themeGet('typeStyles.card.imageAbove.mobile.heading')}
      }
    }
    h2,
    h3 {
      font-size: 1rem;
      font-family: ${themeGet('fonts.sans')};
      margin: 0;
      line-height: 2.125rem;
      letter-spacing: -0.5px;
      font-weight: normal;
      @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
        font-size: 1.31rem;
        line-height: 1.88rem;
      }
    }

    div {
      justify-content: left;

      @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
        flex-direction: column;
      }
    }

    nav {
      @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
        width: 100%;
      }

      h3,
      h4 {
        font-weight: 900;
        margin-bottom: 0.75rem;
        @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
          font-size: 1rem;
        }
      }
      li {
        margin: 0;
        padding: 0;
        margin-bottom: 1rem;
        background-color: white;
        padding: 0;
        a {
          padding: 1rem 2rem;
          display: block;
        }
        opacity: 1;
        &:hover {
          opacity: 0.6;
        }
        @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
          line-height: 1rem;
          margin: 10px 0;
        }
        a {
          text-decoration: none;
          span {
            font-size: 1rem;
            line-height: 1.3125rem;
            @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
              font-size: 0.9rem;
            }
          }
        }
      }
    }
  }
  &.full-width {
    width: 100%;
    background: ${themeGet('colors.spinLight')};
  }
`;

export const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

