import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const StyledTimeline = styled.div`
  p {
    color: ${themeGet('colors.spinTextDark')};
  }
  .vertical-timeline-element::before {
    content: '';
    position: absolute;
    top: 0;
    left: 23px;
    height: 140%;
    width: 4px;
  }

  .vertical-timeline-element.color-0::before {
    background: #3e9aff;
  }
  .vertical-timeline-element.color-1::before {
    background: #7be6cf;
  }
  .vertical-timeline-element.color-2::before {
    background: #ffe455;
  }
  .vertical-timeline-element.color-3::before {
    background: #ff55c5;
  }
  .timeline-date {
    display: none;
  }
`;

export const ImageWrapper = styled.div`
  width: 20vw;
  display: inline-block;
  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    width: 80vw;
    margin: 0 auto;
    display: block;
  }
`;

export const ContentWrapper = styled.div`
  max-width: 50vw;
  display: inline-block;
  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    width: 100%;
    max-width: 100%;
    display: block;
  }
`;

export const DateIconWrapper = styled.p`
  color: ${themeGet('colors.spinTextDark')};
  width: 100%;
  text-align: center;

  padding-top: 10px;
`;
