import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const StyledPageNavigation = styled.section`
  margin-bottom: 20px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 1.25rem;
    line-height: 1.5em;
    font-family: 'Rubik', sans-serif;
    font-weight: 900;
    letter-spacing: 0;
    margin-top: 15px;
    margin-bottom: 10px;
    @media only screen and (max-width: ${themeGet('breakpoints.sm')}) {
      font-size: 1rem;
    }
  }

  ul {
    li {
      margin-bottom: 0;

      @media only screen and (max-width: ${themeGet('breakpoints.sm')}) {
        padding-bottom: 0;
      }

      a {
        font-size: 1.25rem;
        color: ${themeGet('colors.spinTextDark')};

        @media only screen and (max-width: ${themeGet('breakpoints.sm')}) {
          font-size: 0.88rem;
          line-height: 2.1rem;
        }
      }
    }
  }
  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    ul {
      flex-direction: column;
    }
  }
`;
