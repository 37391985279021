import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import LinkList from '../core/LinkList';
import Card from '../core/Card';

import { formatDate, getPatternCardStyle } from '../../utils/helpers';

import { CardsWrapper } from './styles';
//
/**
 * Represents a list of Card components.
 * @param {string} contentType required contentType: 'Cards', 'Events' or 'Upcoming Events'
 * @param {array} items required list of Card objects
 * @param {array} ctaReference [optional=[]] list of links/buttons
 * @param {number} columns [optional=3] - how many items should fit on screen
 * @param {boolean} horizontalScroll [optional=true] - should scroll using horizontal scroll
 */

const CardsBlock = ({
  contentType,
  components,
  columns,
  horizontalScroll,
  ctaReference,
  imagePosition,
  headingLevel,
}) => {
  columns = columns.length > 1? columns.slice(0, 1) : columns;
  const columnStyle = `sectionColumns_${columns}`;
  // todo: can this happen in the data layer?
  

  if (components && components.length) {
    let hasPullQuote;
    const cardElements = components.map((item) => {
      // "card is empty" should no longer happen after Oleh fix
      const cardIsNotEmpty = Object.keys(item).length;
      if (cardIsNotEmpty) {
        let title;
        let links;
        let linksTest;

        let dateTime;
        let cardDescription;
        let isRich = false;

        if (contentType === 'Cards') {
          title = item.cardTitle || item.title || '';
          links = [{ ...item.components }] || [{ ...item.listComponents }]|| [];
          linksTest = item.componentsTest || [];
          cardDescription = item.description?.description || '';

          if (
            item.componentStyle === 'rich-text' ||
            item.componentStyle === 'pull-quote'
          ) {
            title = item.components.textBlockTitle?.raw;
            cardDescription = item.components.content?.references.length > 0 ? item.components.content : item.components.content?.raw;
            isRich = true;
            links = [];
            linksTest = [];
            hasPullQuote = true;
          }
        } else if (['Events', 'Upcoming Events'].includes(contentType)) {
          const linkDetails = item.components;
          title = (linkDetails && linkDetails.headline) || '';
          links = (linkDetails && linkDetails.ctaLinks) || [];
          dateTime = (linkDetails && linkDetails.dateTime) || '';
        }

        if (item.sys.contentType.sys.id === 'componentList') {
          if (
            item.components?.length > 0 &&
            item.components[0].sys.contentType.sys.id == 'callToAction' || (item.listComponents.length > 0 &&
              item.listComponents[0].sys.contentType.sys.id == 'callToAction')
          ) {
            return (
              <LinkList
                key={item.id}
                links={item.components || item.listComponents}
                className="accordion-links"
                ctaStyle=""
              />
            );
          }
          let items  = item.components || item.listComponents;
          return items.map((card, index) => {
            let cardProps = card;

            const isPaternCard = !!card.image;
            if (isPaternCard) {
              const cardLook = getPatternCardStyle(index);
              cardProps = {
                ...cardProps,
                cardType: cardLook.cardType,
                bkgColour: cardLook.backgroundColor,
              };
            }
            return (
              <Card key={card.id} {...cardProps} headingLevel={headingLevel}  title={card.cardTitle} description={card.description?.description}/>
            );
          });
        }

        return (
          <Card
            key={`${item.id}__${title}`}
            title={title}
            dateTime={dateTime && formatDate(dateTime)}
            description={cardDescription || ''}
            bkgColour={item.bkgColour}
            links={links}
            linksTest={linksTest}
            image={item.image}
            className={imagePosition}
            displayDevice={item.displayDevice || null}
            isRich={isRich}
            // rawText={item.components ? item.components[0].rawText : null}
            columns={columns}
            componentStyle={item.componentStyle}
            ctaStyle={item.ctaStyle}
            ctaStyleTest={item.ctaStyleTest}
            headingLevel={headingLevel}
          />
        );
      }
      return null;
    });

    const cssStyle = horizontalScroll ? 'horizontal-scroll' : '';

    return (
      <>
        <CardsWrapper
          className={`cardsBlock ${cssStyle} ${
            imagePosition || ''
          } ${columnStyle}${hasPullQuote ? ' hasPullQuote' : ''}`}
          columns={columns}
        >
          {cardElements}
        </CardsWrapper>
        {ctaReference && !!ctaReference.length && (
          <LinkList
            links={ctaReference}
            ctaStyle="solid-on-dark-bg hvr-bounce-to-right"
          />
        )}
      </>
    );
  }
};

CardsBlock.propTypes = {
  contentType: PropTypes.string.isRequired,
  components: PropTypes.array.isRequired,
  columns: PropTypes.string,
  horizontalScroll: PropTypes.bool,
  ctaReference: PropTypes.array,
};

CardsBlock.defaultProps = {
  columns: 3,
  horizontalScroll: false,
  ctaReference: [],
};

export default CardsBlock;
