import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { getLinkUrl } from '../../utils/helpers';
import Link from '../core/Link';
import StockPreview from '../StockPreview';
import { LocaleProvider } from '../core/Context/LocaleProvider';

import {
  StockInfoSection,
  StyledLink,
  StyledLinks,
  StyledStockPreview,
} from './styles';

/**
 * Represents a preview of stock data
 * @param {array} items required links to investor reports and stock info section.
 * @example
 * [{
 *    id: "id11",
 *    componentStyle: "stock-information",
 *    title: "Stock Information",
 *    items: [
 *      {
 *        id: "id2",
          ctaReference: [
            {
              ariaLabel: "Click to view all Spin Master Stock Information",
              id: "4r1GyC7zgjnzAjHSE0YRGZ",
              linkURL: "stock-info",
              queryString: "",
              textLink: "All Stock information "
            }
          ]
        }
      ]
    },
    {
      id: "id11",
      componentStyle: "call-to-action"
      title: "",
      items: [
 *      {
 *        id: "id1",
 *        ariaLabel: "Click to view ...",
 *        linkURL: "url",
 *        textLink: "Report1",
 *        mediaItem: {
 *          title: "2019 Anual report",
 *          file: {
 *            url: "www.linktomedia.com",
 *          }
 *        }
 *      }
 *    ]
 *  }
 * ];
 */
const IRStockSection = ({ components }) => {
  let linksToReports;
  let stockInfoBlock;
  let stockLinks;
  const locale = useContext(LocaleProvider);
  if (components.length) {
    stockInfoBlock = components.find(
      (link) => link.componentStyle === 'stock-information',
    );
    const reports = components.filter(
      (link) => link.componentStyle === 'call-to-action',
    );
    linksToReports = reports.map((link) => {
      const { textLink, id, ariaLabel } = link.components;
      const url = getLinkUrl(link.components);
      return (
        <div className="linkProtector" key={id}>
          <Link
            children={textLink}
            to={url}
            ariaLabel={ariaLabel}
            className="brand-blue hvr-bounce-to-right"
          />
        </div>
      );
    });
  }

  const sectionTitle = (stockInfoBlock && stockInfoBlock.cardTitle) || '';
  const sectionDescription =
    (stockInfoBlock && stockInfoBlock.description.description) || '';
  const url = getLinkUrl(stockInfoBlock.components.ctaReference[0],locale);
  return (
    <StockInfoSection className="stockInfoSection">
      <StyledStockPreview className="styledStockPreview">
        {sectionTitle && <h2 className="title">{sectionTitle}</h2>}
        {sectionDescription && <h2 className="title">{sectionDescription}</h2>}
        <StockPreview fullView={false} />
        {stockInfoBlock.components &&
            <div className="linkProtector" key={stockInfoBlock.components.ctaReference[0].id}>
              <StyledLink
                children={stockInfoBlock.components.ctaReference[0].textLink}
                to={url}
                ariaLabel={stockInfoBlock.components.ctaReference[0].ariaLabel}
              />
            </div>
        }
      </StyledStockPreview>
      {linksToReports && <StyledLinks>{linksToReports}</StyledLinks>}
    </StockInfoSection>
  );
};

IRStockSection.propTypes = {
  components: PropTypes.array.isRequired,
};

export default IRStockSection;
