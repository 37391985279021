import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const StyledArticle = styled.article`
  margin: 0 2rem 4rem;
  padding-left: 2rem;
  padding-right: 2rem;
  max-width: ${themeGet('document.maxWidth')};

  &.accordion-article {
    max-width: none;
  }

  h1 {
    margin-bottom: 20px;
  }
  h2 {
    letter-spacing: 0;
  }
  & > .styledLinkList {
    margin-left: 0;
  }
  .spinmaster-article {
    margin: 0 0 40px 0;
    color: ${themeGet('colors.spinTextDark')};
    font-size: 0.81rem;
    line-height: 1.25rem;

    p {
      font-size: 1rem;
      line-height: 1.65rem;
      margin-top: 1.9375rem;
      margin-bottom: 1.45rem;
      @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
        font-size: 0.875rem;
        line-height: 1.5rem;
      }
    }
    ul,
    ol {
      margin-top: 1.9375rem;
      li > p {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
    blockquote {
      display: block;
      margin: 2rem auto;
      p {
        color: ${themeGet('colors.spinTextDark')};
        font-family: ${themeGet('fonts.sans')};
        font-size: 1.875rem;
        line-height: 2.8125rem;
        font-style: italic;
        font-weight: 600;
        text-align: center;
        margin: 0;
      }
    }

    @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
      font-size: 0.875rem;
      blockquote {
        display: block;
        margin: 26px 1rem;
        p {
          font-size: 1.5625rem;
          line-height: 2.1875rem;
          text-align: left;
        }
      }
    }
  }
  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    margin-top: 6rem;
    margin: 0 2rem;
  }
`;

export const StyledImage = styled.img`
  display: block;
  margin: 26px auto;
`;
