import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

import Banner from '../../components/Banner';

export const SliderCarouselWrapper = styled.div`
    position: relative;
    width: 100%;

    .slick-dots {
        position: sticky;
    }

    .slideBlock{
      display: flex !important;
    }

    .carousel__circle {
        border: 2px solid  ${themeGet('colors.spinLight')};
        height: 12px;
        width: 12px;
        border-radius: 100%;
        margin: 10px; }
      
      ul .slick-active .carousel__circle {
        background-color: ${themeGet('colors.spinDark')}; }


        .slick-slider .slick-list {
            z-index: 0; }
          .slick-slider .carousel__dots {
            display: block;
            position: absolute;
            left: 50%;
            transform: rotate(-90deg);
            bottom: 0; }
    
`;

/*

export const SliderFeatureImage = styled.img`

  object-fit: contain;
  object-position: center center;
  ${themeGet('borders.default')};
  box-sizing: border-box;
  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    display: none;
  }
`;


export const SliderHeading = styled.div`
  display: block
`;

export const SliderSubHeading = styled.div`
    display: block
`;

*/

export const SliderContainer = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  
  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    flex-direction: column;
    align-items: center;
  }
`;

export const SliderImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%;
  ${themeGet('borders.default')};
  box-sizing: border-box;

  .imageBlock{
    width: 1017px;
  }

  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    width: 100%;
  }
`;

export const SliderFeatureImage = styled.img`
  object-fit: contain;
  object-position: center center;
  ${themeGet('borders.default')};
  box-sizing: border-box;

  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    width: 100%;
    display: block;
  }
`;

export const SliderTextContainer = styled.div`
  width: 30%;
  text-align: center;
  ${themeGet('space.3')};
  background: ${themeGet('colors.spinLight')};
  color: #ffff;
  padding: 5%;

  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    text-align: center;
    width: 100%;
  }
`;

export const SliderHeading = styled.h2`
  display: block;
  margin-bottom: ${themeGet('space.2')};
  font-weight: bold;


  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    margin-bottom: ${themeGet('space.1')};
  }
`;

export const SliderSubHeading = styled.div`
  display: block;
`;

export const DescriptionWrapper = styled.div`
  text-align: center;
  display: inline-block;
  max-width: 64%;
  width: 100%;
  margin: 50px auto 20px;
  font-size: 1.875rem;
  line-height: 2.8125rem;

  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    max-width: 90%;
    text-align: left;
  }
`;


export const CoreBanner = styled(Banner)`
  .backgroundVideo {
    height: unset !important;
    & > div {
      width: 100% !important;
      min-height: 603px;
      @media only screen and (max-width: Calc(1600px + 5rem)) {
        min-height: Calc(Calc(67vw - 5rem) * 0.5625);
      }
      iframe {
        height: 100%;
        @media only screen and (max-width: Calc(1600px - 64px)) {
        }
      }
    }
  }
  &.bannerLeft {
    .backgroundVideo {
      & > div {
        margin-left: 0;
        width: 100% !important;
      }
    }
  }
  .bannerBodyWrapper {
    width: 33% !important;
    max-width: 33% !important;
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &:last-child {
      margin-bottom: 0;
    }

    @media only screen and (max-width: ${themeGet('breakpoints.lg')}) {
      width: unset !important;
      max-width: unset !important;
    }
  }
  .spinmaster-background-wrapper {
    align-items: stretch !important;
    height: unset !important;
    min-height: unset !important;
    & > .onlyDesktop,
    & > .backgroundVideo {
      width: 67% !important;
      height: unset !important;
      min-height: unset !important;
      @media only screen and (max-width: ${themeGet('breakpoints.lg')}) {
        width: unset !important;
      }
    }

    & > .onlyDesktop {
      .backgroundImage {
        min-height: 100%;
        height: 100%;
        img {
          object-position: center center !important;
        }
      }
    }
  }
  @media only screen and (max-width: ${themeGet('breakpoints.lg')}) {
    .spinmaster-background-wrapper {
      display: block !important;
      .backgroundVideo > div iframe,
      .backgroundVideo > div {
        min-height: 56.25vw !important;
      }
    }
    .styledLinkList {
      a {
        margin-right: 1rem;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    .mobileWrapper,
    .gatsby-image-wrapper {
      overflow: hidden;
    }
  }

  // h1 {
  //   font-size: 1.3125rem;
  //   line-height: 1.875rem;
  // }
  // p {
  //   font-size: 0.875rem;
  //   line-height: 1.5rem;
  // }
`;
