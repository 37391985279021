import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

import { LocaleProvider } from '../../core/Context/LocaleProvider';
import { getTranslation } from '../../../utils/helpers';

import {
  StyledUploadField,
  ErrorMessage,
  ThumbsContainer,
  Thumb,
  ThumbInner,
  ClearUploadButton,
} from './styles';

/**
 * Represents an upload field.
 * @param {string} name required
 * @param {string} label required
 * @param {string} type required
 * @param {boolean} isRequired required
 * @param {boolean} isInvalid required
 * @param {function} onChange required
 * @param {string} value required
 * @param {string} fieldErrorText required
 */
const FileUploadField = ({
  name,
  label,
  subtext,
  type,
  files,
  isRequired,
  onChange,
  isInvalid,
  fieldErrorText,
  fileTypes,
}) => {
  const locale = useContext(LocaleProvider);
  const bioCopy =
    getTranslation('Clear', locale.localeTranslation, locale.code) || 'Clear';
  const handleDrop = (acceptedFiles) => {
    onChange(name, acceptedFiles);
  };

  // const [thumbs, updateThumbs] = useState(null);

  const settings = {};
  if (fileTypes.length > 0) settings.accept = `.${fileTypes.join(',.')}`;
  settings.onDrop = handleDrop;

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    return files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);
  const thumbs =
    files &&
    files.map(({ name, preview }) => (
      <Thumb key={name}>
        <ThumbInner>
          <img alt="" src={preview} />
        </ThumbInner>
      </Thumb>
    ));

  const { getRootProps, getInputProps } = useDropzone(settings);
  return (
    <>
      <StyledUploadField {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <div>
          <p>{label}</p>
          <p className="em">{subtext}</p>
        </div>
        <ThumbsContainer>{thumbs}</ThumbsContainer>
      </StyledUploadField>
      {files.length > 0 && (
        <ClearUploadButton
          className="clearUpload"
          onClick={() => {
            files = [];
            onChange(name, null);
          }}
        >
          Clear
        </ClearUploadButton>
      )}
      <ErrorMessage aria-live="polite">
        {isInvalid && fieldErrorText}
      </ErrorMessage>
    </>
  );
};

FileUploadField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  files: PropTypes.array,
  isRequired: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  isInvalid: PropTypes.bool,
  fieldErrorText: PropTypes.string,
};

FileUploadField.defaultProps = {
  files: [],
  fieldErrorText: '',
  isInvalid: false,
};

export default FileUploadField;
