import React from 'react';
import PropTypes from 'prop-types';

/**
 * Represents a hidden field.
 * @param {string} name required
 * @param {string} defaultValue required
 */
const HiddenField = ({ name, defaultValue }) => (
  <input id={name} type="hidden" name={name} value={defaultValue} />
);

HiddenField.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
};

export default HiddenField;
