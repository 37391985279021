import React from 'react';
import PropTypes from 'prop-types';

import LinkList from '../core/LinkList';
import Card from '../core/Card';
import { sortByOrder } from '../../utils/helpers';
import {
  contentTypeToComponentMap,
  remappedComponentItems,
} from '../../contentTypeToComponent';
import Article from '../core/Article';
import { MediaSection3DWrapper, GroupWrapper } from './styles';

/**
 * Represents a list of Card components.
 * @param {string} contentType required contentType: 'Cards', 'Events' or 'Upcoming Events'
 * @param {array} items required list of Card objects
 * @param {array} ctaReference [optional=[]] list of links/buttons
 * @param {number} columns [optional=3] - how many items should fit on screen
 * @param {boolean} horizontalScroll [optional=true] - should scroll using horizontal scroll
 */
const MediaSection3Deep = ({
  contentType,
  components,
  columns,
  horizontalScroll,
  ctaReference,
  imagePosition,
  headingLevel,
}) => {
  const columnStyle = `sectionColumns_${columns}`;
  if (components && components.length) {
    const sectionElements = components.map((item, i) => {
      //sortByOrder(item.items);
      const components = item.listComponents.map((subitem, index) => {
        const revisedItem = remappedComponentItems(subitem);
        const key =
          revisedItem.id || `${Object.values(revisedItem)[0]}-${index}`;
        if (
          revisedItem.sys.contentType.sys.id === 'componentCard' &&
          revisedItem.componentStyle === 'rich-text'
        ) {
          const mappedItems = {
            ...revisedItem,
            items: [{ items: [revisedItem.items[0]] }],
          };
          return (
            <Article key={key} {...mappedItems} headingLevel={headingLevel} />
          );
        }

        const Component =
          contentTypeToComponentMap[revisedItem.contentType] &&
          contentTypeToComponentMap[revisedItem.contentType].component;

        if (Component) {
          // TODO use id when it's available in Oleh
          return (
            <Component key={key} {...revisedItem} components={revisedItem.items}  headingLevel={headingLevel} />
          );
        }
        if (
          revisedItem.resolverType === 'componentCard' &&
          revisedItem.componentStyle === 'rich-text'
        ) {
          const mappedCardData = {
            title: JSON.stringify(revisedItem.items[0].titleRT),
            description: JSON.stringify(revisedItem.items[0].content),
            isRich: true,
            links: [],
            displayDevice: revisedItem.displayDevice || null,
            content: revisedItem.items ? revisedItem.items[0].content : null,
            componentStyle: revisedItem.componentStyle,
            columns: 1,
            bkgColour: revisedItem.bkgColour,
          };
          // return <CardsBlock>Text Block</CardsBlock>;
          return (
            <Card
              key={
                revisedItem.id +
                index +
                Math.floor(Math.random() * 10000000) +
                Math.random().toString(36).substring(7)
              }
              {...mappedCardData}
              headingLevel={headingLevel}
            />
          );
        } else if (revisedItem.resolverType === 'componentList') {
          sortByOrder(revisedItem.items);
          if (
            revisedItem.items.length > 0 &&
            revisedItem.items[0].resolverType == 'callToAction'
          ) {
            return (
              <LinkList
                key={revisedItem.id}
                links={revisedItem.items}
                className="accordion-links"
                ctaStyle=""
              />
            );
          }
          return item.items.map((card) => {
            return (
              <Card
                key={card.description}
                {...card}
                headingLevel={headingLevel}
              />
            );
          });
        }
        return null;
      });
      return <>{components}</>;
    });

    return (
      <MediaSection3DWrapper className="mediaSection3DWrapper">
        {sectionElements}
      </MediaSection3DWrapper>
    );
  }
  return null;
};

MediaSection3Deep.propTypes = {
  contentType: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  columns: PropTypes.number,
  horizontalScroll: PropTypes.bool,
  ctaReference: PropTypes.array,
};

MediaSection3Deep.defaultProps = {
  columns: 3,
  horizontalScroll: false,
  ctaReference: [],
};

export default MediaSection3Deep;
