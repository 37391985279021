import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const FootnotesBlock = styled.div`
  margin-left: 20px;
`;

export const TableWrapper = styled.div`
  overflow-x: overlay;
  padding-bottom: 30px;
`;

export const StyledTable = styled.table`
  &.topHeader {
    max-width: 1600px;
    th,
    td {
      padding-left: 1.9375rem;
      font-family: ${themeGet('fonts.sans')};
      letter-spacing: 0;
      vertical-align: middle;
    }
    td:nth-child(even) {
      background-color: ${themeGet('colors.spinSecondaryFour')};
    }
    thead {
      th:first-child {
        border-radius: 8px 0 0 8px;
      }
      th:last-child {
        border-radius: 0 8px 8px 0;
        padding-right: 15px;
      }
      th {
        background-color: ${themeGet('colors.spinSecondaryThree')};
        color: ${themeGet('colors.spinUltraDark')};
        font-size: 1.3125rem;
        line-height: 1.9375rem;
        font-weight: normal;
        border-bottom: none;
      }
    }
    tbody {
      td,
      span {
        color: ${themeGet('colors.spinDark')};
        font-size: 1rem;
        line-height: 1.375rem;
      }
    }
    @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
      width: 800px;
      th,
      td {
        line-height: 1.625rem;
        padding-left: 1rem;
      }
      thead {
        th {
          color: #01124c;
          font-size: 1rem;
          font-weight: 600;
        }
      }
      tbody {
        td,
        span {
          font-size: 0.875rem;
        }
      }
    }
  }
`;
