import React, { useState, useContext } from 'react';
import _ from 'lodash';
import slugify from 'slugify';

import { LocaleProvider } from '../components/core/Context/LocaleProvider';
import Filter from '../components/core/Filter';
import Title from '../components/core/Title';
import LinkList from '../components/core/LinkList';
import { StyledFilterBlock } from '../components/core/Filter/styles';
import { getTranslation } from '../utils/helpers';
import HeadingElement from '../components/core/HeadingElement';

/**
 * Represents a component with or without filter.
 * @param {component} required - React component with props.
 * Required props: components, filterExists.
 * Optional props: title, hasShowAll
 * @returns {component} returned component has all given props and 'item' prop
 */
function withFilter(WrappedComponent) {
  return function EnhancedComponent(props) {
    const {
      components,
      filterExists,
      hasShowAll,
      filterTagReference,
      title,
      pressReleasesCta,
      translations,
      headingLevel,
      contentType,
    } = props;
    
    const [visibleComponents, updateVisibleComponents] = useState(components);
    const getMultipleTags = (categories) => {
      return categories.map((category) => category.tagName);
    };

    const getFilterBy = (item) => {
      const tag = _.get(
        item,
        'listComponents.tag',
        item.tag || item.title || item.components?.tag
      )?.toString();
      const leadershipTags = item.helpshiftRepoTags
        ? getMultipleTags(item.helpshiftRepoTags)
        : item.brandReference && item.brandReference[0].categories
        ? getMultipleTags(item.brandReference[0].categories)
        : item.categories
        ? getMultipleTags(item.categories)
        : [];
      return typeof tag === 'string' ? [tag] : leadershipTags;
    };

    const handleClick = (selectedCriteria) => {
      if (selectedCriteria === showAllCopy) {
        updateVisibleComponents(components);
        return;
      }
      const updatedVisibleList = components.filter((item) =>
        getFilterBy(item).includes(selectedCriteria)
      );
      updateVisibleComponents(updatedVisibleList);
    };

    const isNumeric = (num) =>
      (typeof num === 'number' ||
        (typeof num === 'string' && num.trim() !== '')) &&
      !isNaN(num);

    if (!filterExists) {
      return (
        <>
          {title && (
            <HeadingElement headingLevel={headingLevel}>{title}</HeadingElement>
          )}
          <WrappedComponent
            {...props}
            headingLevel={title ? headingLevel + 1 : headingLevel}
            components={visibleComponents}
          />
        </>
      );
    }
    let optionsNoRepetitions;
    if (filterTagReference) {
      optionsNoRepetitions = filterTagReference.map((item) => {
        return item.tagName;
      });
    } else {
      const options = components.map((component) => {
        return getFilterBy(component);
      });

      optionsNoRepetitions = Array.from(new Set(options.flat()));
    }
    // if it's a number (for example, "2018" or 2018), sort filter options from max to min
    if (isNumeric(optionsNoRepetitions[0])) {
      optionsNoRepetitions.sort((a, b) => b - a);
    }

    const locale = useContext(LocaleProvider);

    let showAllCopy = '';
    if (hasShowAll) {
      const translationObject = getTranslation(
        'show_all',
        translations,
        locale
      );
      showAllCopy = translationObject ? 'Show All' : optionsNoRepetitions[0];
    }

    const pressReleasesCtaExists =
      !!pressReleasesCta && !!pressReleasesCta.length;

    const filterTypeName = contentType
      ? slugify(contentType, {
          lower: true,
          strict: true,
        })
      : '';
    return (
      <>
        {title && (
          <HeadingElement
            headingLevel={headingLevel}
            hasWrapper={true}
            className={`filter_title title_${filterTypeName}`}
          >
            {title}
          </HeadingElement>
        )}
        <StyledFilterBlock
          className={`styledFilterBlock filterBlock_${filterTypeName} ${
            pressReleasesCtaExists ? 'two-columns' : 'one-column'
          }`}
        >
          <div className="filter-block-filter">
            <Filter options={optionsNoRepetitions} onClick={handleClick} showAllText={showAllCopy}/>
          </div>
          {pressReleasesCtaExists && (
            <div className="filter-block-ctas">
              <LinkList
                links={pressReleasesCta}
                ctaStyle="solid-on-dark-bg hvr-bounce-to-right"
              />
            </div>
          )}
        </StyledFilterBlock>
        <WrappedComponent
          {...props}
          headingLevel={title ? headingLevel + 1 : headingLevel}
          components={visibleComponents}
        />
      </>
    );
  };
}

export default withFilter;
