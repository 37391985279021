import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const StyledWrapper = styled.section`
  border-radius: 5px;
  background-color: ${themeGet('colors.spinSecondaryFour')};
  margin-bottom: 15px;
  padding: 25px;

  nav,
  h2,
  h3 {
    padding-left: 0;
  }
  h2 {
    font-family: Rubik;
    font-size: 1.875rem;
    font-weight: 900;
    letter-spacing: 0.21px;
  }
  a {
    color: ${themeGet('colors.spinDark')};
    font-size: 0.9375rem;
    line-height: 1.25rem;
    text-decoration: none;
    font-weight: 400;
  }

  &.reports {
    padding: 0;
    margin-bottom: 40px;
    & > h2,
    & > h3 {
    }
    & > h2 {
    }

    .groupsListWrapper {
      padding: 0 2rem 2rem;
      column-gap: 2rem;
    }
    @media only screen and (max-width: ${themeGet('breakpoints.sm')}) {
      .groupsListWrapper {
        padding: 0;
      }
    }
    @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
      padding: 25px 13px;
    }

    h2.title,
    h3.title {
      margin: 0;
      font-family: ${themeGet('fonts.rubik')};
      font-size: 1.875rem;
      font-weight: 900;
      padding: 1rem 2rem;
      background-color: ${themeGet('colors.spinDark')};
      color: white;
      border-radius: 5px 5px 0 0;
      margin-bottom: 1rem;
      @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
        ${themeGet('typeStyles.card.imageAbove.mobile.heading')}
      }
    }
    h2,
    h3 {
      font-size: 1rem;
      font-family: ${themeGet('fonts.sans')};
      margin: 0;
      line-height: 2.125rem;
      letter-spacing: -0.5px;
      font-weight: normal;
      @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
        font-size: 1.31rem;
        line-height: 1.88rem;
      }
    }

    div {
      justify-content: left;

      @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
        flex-direction: column;
      }
    }

    nav {
      @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
        width: 100%;
      }

      h3,
      h4 {
        font-weight: 900;
        margin-bottom: 0.75rem;
        @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
          font-size: 1rem;
        }
      }
      li {
        margin: 0;
        padding: 0;
        margin-bottom: 1rem;
        background-color: white;
        padding: 0;
        a {
          padding: 1rem 2rem;
          display: block;
        }
        opacity: 1;
        &:hover {
          opacity: 0.6;
        }
        @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
          line-height: 1rem;
          margin: 10px 0;
        }
        a {
          text-decoration: none;
          span {
            font-size: 1rem;
            line-height: 1.3125rem;
            @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
              font-size: 0.9rem;
            }
          }
        }
      }
    }
  }
  &.full-width {
    width: 100%;
    background: ${themeGet('colors.spinLight')};
  }
`;

export const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
