import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';

import { postFormData } from '../../service/apiRequests';
import Link from '../core/Link';
import Button from '../core/Button';
import { sortByOrder, getPathFromSlug } from '../../utils/helpers';
import { SitemapProvider } from '../core/Context/SitemapProvider';
import { LocaleProvider } from '../core/Context/LocaleProvider';

import AutocompleteField from './AutocompleteField';
import InputField from './InputField';
import {
  StyledForm,
  Message,
  FormSection,
  SectionHeadingWrapper,
  FieldWrapper,
  Fieldset,
  StyledAutocomplete,
  RippleLoading,
} from './styles';
import TextAreaField from './TextAreaField';
import FileUploadField from './FileUploadField';
import HiddenField from './HiddenField';

/**
 * Represents a form.
 * @param {string} title required
 * @param {string} description required
 * @param {array} fields required
 * @param {string} submitText required
 * @param {object} link [optional={}]
 * @param {function} onChange required
 * @param {boolean} isError [optional=false]
 * @param {array} messages [optional=[]]
 * @param {boolean} formSubmitted [optional=false]
 */
const Form = (props) => {
  // const formData = props.items[0];
  const formData = props.items[0];

  const {
    title,
    description,
    fieldReference,
    submitText,
    submitTarget,
    failureText,
    successText,
    link,
    categories,
  } = formData;
  sortByOrder(fieldReference);

  const [formState, updateForm] = useState({});
  const [formValidityState, updateValidity] = useState({});
  const [buttonDisabled, updateButtonState] = useState(true);
  const [isError, setIsError] = useState('');
  const [messages, setMessages] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const sitemap = useContext(SitemapProvider);
  const locale = useContext(LocaleProvider);

  const validateEmail = (input) => {
    const emailRegex = /^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return emailRegex.test(input);
  };

  const isValid = (target) => {
    const { type, required, value } = target;
    if (!required) {
      return true;
    }
    if (required && value.length > 0) {
      const typeValidationMap = {
        email: validateEmail(value),
        text: true,
      };
      return typeValidationMap[type];
    }
    return false;
  };

  /**
   * get the base64 object representation for the attached image
   *
   * @param {object} file
   */
  const readFileAsync = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        resolve(event.target.result);
      };

      reader.onerror = reject;

      reader.readAsDataURL(file);
    });
  };

  /**
   * handler for dropzone object, replaces the current file in the same with the new one
   * @param {string} name
   * @param {array} acceptedFiles
   */
  const handleFileChange = async (name, acceptedFiles) => {
    if (!acceptedFiles) {
      const files = [];
      updateForm({ ...formState, [name]: files });
      return;
    }
    const files = await Promise.all(
      acceptedFiles.map(async (file) => {
        const fileData = await readFileAsync(file);
        return Object.assign(file, {
          preview: URL.createObjectURL(file),
          fileData,
        });
      }),
    );
    // updateForm({ ...formState, [name]: files });
    if (formState[name]) {
      updateForm({ ...formState, [name]: [...formState[name], ...files] });
    } else {
      updateForm({ ...formState, [name]: files });
    }
  };

  const handleChange = (e) => {
    updateForm({
      ...formState,
      [e.target.name]: e.target.value,
    });

    const updatedValidityState = {
      ...formValidityState,
      [e.target.name]: isValid(e.target),
    };

    const requiredFields = [];
    fieldReference.map((fieldSection) =>
      fieldSection.formFields
        .filter((field) => field.isRequired)
        .map((field) => {
          requiredFields.push(field.fieldId);
        }),
    );
    let isButtonDisabled = false;
    for (let i = 0; i < requiredFields.length; i++) {
      const field = requiredFields[i];
      const invalid = updatedValidityState[field] !== true;

      if (invalid) {
        isButtonDisabled = true;
        break;
      }
    }

    updateValidity(updatedValidityState);
    updateButtonState(isButtonDisabled);
  };

  /**
   * Handle the form submission
   *
   * @param {object} e
   */
  const handleSubmit = async (e) => {
    e.preventDefault();

    setSubmitting(true);

    postFormData(submitTarget, formState)
      .then((result) => {
        if (result.errors) {
          // setMessages(result.errors);
          setIsError(true);
          setSubmitting(false);
        } else {
          let iLink = getPathFromSlug(
            sitemap.pages,
            'page-form-submit-complete',
            locale,
          ).path;

          if (iLink && iLink.indexOf(locale.code) !== -1)
            iLink = iLink.split(`/${locale.code}/`).join('');

          navigate(`/${locale.code}/${iLink}`);
          setIsError(false);
        }
      })
      .catch(() => {
        setIsError(true);
        setSubmitting(false);
        // setMessages([failureText]);
      });
  };

  const messageElements =
    messages &&
    messages.map((message) => (
      <Message key={message} className={isError ? 'error' : 'default'}>
        {message}
      </Message>
    ));

  const linkExists = link && !!Object.keys(link).length;

  if (submitting) {
    return (
      <div>
        {/* <p>your form is being submitted</p> */}
        <RippleLoading>
          <div />
          <div />
        </RippleLoading>
      </div>
    );
  }

  return (
    <div>
      <h3>{title}</h3>
      <p>{description}</p>
      <StyledForm onSubmit={handleSubmit}>
        {fieldReference &&
          fieldReference.map(
            ({ id, title: sectionTitle, formFields, columns }) => {
              sortByOrder(formFields);
              return (
                <FormSection className="formSection" key={id}>
                  <SectionHeadingWrapper>
                    <h4>{sectionTitle}</h4>
                  </SectionHeadingWrapper>
                  <FieldWrapper className={columns === 2 ? 'two-column' : ''}>
                    {formFields.map(
                      ({
                        title: label,
                        name,
                        fieldId,
                        entryType,
                        isRequired,
                        fieldErrorText,
                        placeholder,
                        fieldOptions,
                        options,
                        value: defaultValue,
                      }) => {
                        // equal to 'undefined' makes sure that errors do not appear after initial rendering
                        const fieldValid =
                          formValidityState[name] === undefined ||
                          formValidityState[name];

                        switch (entryType) {
                          case 'Autocomplete':
                            return (
                              <Fieldset key={fieldId}>
                                <AutocompleteField
                                  label={label}
                                  name={fieldId}
                                  type={entryType}
                                  value={formState[fieldId]}
                                  isRequired={isRequired}
                                  onChange={handleChange}
                                  isInvalid={!fieldValid}
                                  fieldErrorText={fieldErrorText}
                                  fieldOptions={fieldOptions || ''}
                                  placeholder={placeholder}
                                />
                              </Fieldset>
                            );
                          case 'File':
                            return (
                              <Fieldset key={fieldId}>
                                <FileUploadField
                                  label={label}
                                  subtext={placeholder}
                                  name={fieldId}
                                  type={entryType}
                                  fileTypes={options}
                                  files={formState[fieldId]}
                                  isRequired={isRequired}
                                  onChange={handleFileChange}
                                />
                              </Fieldset>
                            );
                          case 'Comments':
                            return (
                              <TextAreaField
                                key={`form-field-${fieldId}`}
                                label={label}
                                name={fieldId}
                                type={entryType}
                                value={formState[fieldId] || ''}
                                isRequired={isRequired}
                                onChange={handleChange}
                                isInvalid={!fieldValid}
                                fieldErrorText={fieldErrorText}
                                placeholder={placeholder}
                              />
                            );
                          case 'Hidden':
                            if (!formState[fieldId]) {
                              // set the value in the form the first  run through
                              updateForm({
                                ...formState,
                                [fieldId]: defaultValue,
                              });

                              // add the field to the validity state
                              updateValidity({
                                ...formValidityState,
                                [fieldId]: isValid(defaultValue),
                              });
                            }
                            return (
                              <HiddenField
                                key={`form-field-${fieldId}`}
                                name={fieldId}
                                defaultValue={defaultValue || ''}
                              />
                            );
                          case 'Text':
                          default:
                            return (
                              <Fieldset key={`form-field-${fieldId}`}>
                                <InputField
                                  label={label}
                                  name={fieldId}
                                  type={entryType}
                                  value={formState[fieldId] || ''}
                                  isRequired={isRequired}
                                  onChange={handleChange}
                                  isInvalid={!fieldValid}
                                  fieldErrorText={fieldErrorText}
                                  placeholder={placeholder}
                                />
                              </Fieldset>
                            );
                        }
                      },
                    )}
                  </FieldWrapper>
                </FormSection>
              );
            },
          )}

        {messageElements && !!messageElements.length && messageElements}
        <Button
          className="solid-on-dark-bg"
          type="submit"
          disabled={buttonDisabled}
        >
          {submitText}
        </Button>

        {linkExists && (
          <Link
            children={link.children}
            to={link.to}
            ariaLabel={link.ariaLabel}
          />
        )}
      </StyledForm>
    </div>
  );
};

Form.defaultProps = {
  title: '',
  description: '',
  link: {},
  messages: [],
  isError: false,
  formSubmitted: false,
};

export default Form;
