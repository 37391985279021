import React, { useContext } from 'react';
import _ from 'lodash';

import ImageWithLogo from '../core/ImageWithLogo';
import { LocaleProvider } from '../core/Context/LocaleProvider';
import { getLocalizedLink } from '../../utils/helpers';
import Image from '../core/Image';
import HeadingElement from '../core/HeadingElement';

import {
  FeaturedBrandsWrapper,
  FeaturedBrandsGridList,
  BrandItem,
  BrandItemMicro,
} from './styles';

const FeaturedBrands = ({ components, microLayout, title, headingLevel }) => {
  const locale = useContext(LocaleProvider);
  const elements = components
    .filter((brand) => brand.brandReference != null)
    .map((brand) => {
      const { id, pageName, slug, ogImage } = brand;
      if (!brand.brandReference) {
        return null;
      }

      const { featureImage, imageLogo } = brand.brandReference[0];
      return (
        <BrandItem key={id} className="brandItem">
          <a href={`/${locale.code}/brands/${slug}`}>
            <ImageWithLogo
              srcImage={featureImage || null}
              srcLogo={imageLogo || null}
            />
            <HeadingElement headingLevel={headingLevel}>
              {pageName}
            </HeadingElement>
          </a>
        </BrandItem>
      );
    });
  const elementsMicro = components
    .filter((brand) => brand.brandReference != null)
    .map((brand) => {
      const { id, slug } = brand;
      const { imageLogo } = brand.brandReference[0];
      // TODO: default text for the logos or translated title for image needs to be enabled
      return (
        <BrandItemMicro className="brandItemMicro" key={id}>
          <a
            title={imageLogo.title || 'Brand Logo'}
            href={getLocalizedLink(`brands/${slug}`, locale)}
          >
            <Image
              alt={imageLogo.title || 'Brand Logo'}
              imageSharp={imageLogo ? imageLogo : null}
            />
          </a>
        </BrandItemMicro>
      );
    });
  if (!microLayout) {
    return (
      <FeaturedBrandsWrapper>
        <FeaturedBrandsGridList
          baseWidth="440px"
          className="featuredBrandsWrapper"
        >
          {elements}
        </FeaturedBrandsGridList>
      </FeaturedBrandsWrapper>
    );
  }
  return (
    <FeaturedBrandsWrapper className="microBrands">
      <div className="microBrandTitle">
        <HeadingElement headingLevel={headingLevel}>{title}</HeadingElement>
      </div>
      <div className="microBrandIconList">{elementsMicro}</div>
    </FeaturedBrandsWrapper>
  );
};

FeaturedBrands.propTypes = {};

FeaturedBrands.defaultProps = {};

export default FeaturedBrands;
