import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const StyledAutocomplete = styled.div`
  width: 100%;
  min-width: auto !important;
  margin-top: -2px;
  border: 1px solid grey;
  position: absolute;
  overflow: auto;
  max-height: 300px;
  z-index: 80;
  color: ${themeGet('colors.spinTextDark')};
  font-size: 0.8125rem;
  .em {
    font-style: italic;
  }
  > div {
    padding: 6px;
  }
`;

export const ErrorMessage = styled.span`
  color: #e30606;
`;

export const Label = styled.label`
  color: ${themeGet('colors.spinTextDark')};
`;

export const Input = styled.input`
  color: ${themeGet('colors.spinTextDark')};
  font-size: 0.8125rem;
`;
