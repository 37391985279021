import fetch from 'cross-fetch';

export async function postData(url = '', data = {}) {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return response.json();
}

/**
 * Submit a form and pass through the data from the form state
 *
 * @param {string} path - the name of the function in the orchestration layer
 * @param {object} data - the form data that is passed through
 */
export async function postFormData(path, data) {
  const OLEH_URL = `${process.env.GATSBY_OLEH_URL}`;
  const response = await fetch(OLEH_URL.split('graphql').join(path), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  return response.json();
}
