import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import RichText from '../../components/core/RichText';
import Layout from '../../components/layout';
import SEO from '../../components/core/SEO';
import theme, { GlobalStyles } from '../../themes';

import { SectionDescription, DescriptionWrapper, CoreBanner } from './styles';

const ReusableBrandPromo = ({ pageContext, data }) => {
  // const { sitemap } = pageContext;
  const { slug, components, node_locale, pageName, metaTitle } =
    data.contentfulPageMasterLayout;

  const themeColors = [
    'spinSecondaryTwo',
    'spinSecondaryThree',
    'spinSecondaryOne',
    'spinAccentOne',
    'spinAccentTwo',
  ];
  const headingLevel = 1;

  return (
    <>
      {/* <SectionDescription id="sectionDescription">
        <DescriptionWrapper>
          <RichText
            richTextContent={JSON.parse(
              components[5].description.raw
            )}
          />
        </DescriptionWrapper>
      </SectionDescription> */}
      {components &&
        components.map((item, index) => {
          const {
            id,
            description,
            image,
            title,
            videoCodeUrl,
            ctaReference,
            mediaType,
          } = item;
          // const ind = (startIndex + index) % themeColors.length;
          const mappedVideo = {
            file: {
              contentType: 'video/youtube',
              urlSharp: { publicURL: videoCodeUrl },
            },
          };

          if (id === 'a5d9aa8e-207b-5893-8583-58f9fb57c336') {
            return (
              <CoreBanner
                backgroundColour={themeColors[index % themeColors.length]}
                id={`brandPromoBanner_${id}`}
                heading={title}
                richTextEditor={description}
                bkgImage={
                  mediaType === 'Video' && mappedVideo
                    ? mappedVideo
                    : image || null
                }
                bkgImageMobile={image}
                bannerCtaReference={ctaReference || null}
                contentPosition={index % 2 === 0 ? 'Left' : 'Right'}
                controls
                playing={false}
                className="coreBanner scalingHeight "
                headingLevel={headingLevel + 1}
              />
            );
          }
          return null;
        })}
    </>
  );
};

ReusableBrandPromo.propTypes = {
  pageContext: PropTypes.shape({
    metaTitle: PropTypes.string,
    metaDescription: PropTypes.oneOfType([PropTypes.object]),
    metaKeywords: PropTypes.string,
    locale: PropTypes.oneOfType([PropTypes.object]),
    locales: PropTypes.oneOfType([PropTypes.object]),
    sitemap: PropTypes.oneOfType([PropTypes.object]),
    siteConfiguration: PropTypes.oneOfType([PropTypes.object]),
  }).isRequired,
};

export default ReusableBrandPromo;

export const query = graphql`
  query BrandTemplate($id: String!, $node_locale: String!) {
    contentfulDevGlobalSiteConfiguration(node_locale: { eq: $node_locale }) {
      ...SiteConfiguration
    }
    locales: allContentfulDevGlobalSiteConfiguration {
      nodes {
        node_locale
        languageSwitcherText
      }
    }
    contentfulPageBrand(id: { eq: $id }) {
      slug
      node_locale
      pageName
      metaTitle
      metaDescription {
        metaDescription
      }
      brandReference {
        ...BrandReference
      }
      brandPromoBanners {
        ...BrandPromoBanners
      }
      brandSupportLinks {
        ...BrandSupportLinks
      }
    }
  }
`;
