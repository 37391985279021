import React from 'react';
import PropTypes from 'prop-types';

import LinkGroup from '../core/LinkGroup';
import HeadingElement from '../core/HeadingElement';
import { sortByOrder } from '../../utils/helpers';

import { StyledWrapper, List } from './styles';

/**
 * Represents a financial report component in 'Financial Reports' page.
 * Component consists of title and the list of LinkGroup components.
 * @param {string} title required - The title of the groups.
 * @param {array} groupList required - The list of groups.
 * @param {number} headingLevel required - Heading level.
 * @param {string} className [optional=""] - css class
 */
const LinkGroups = ({ title, className, groupList, headingLevel }) => {
  let groups;
  const classes = className;
  if (groupList && groupList.length) {
    if (!groupList[0].items) {
      return (
        <StyledWrapper className={`linkGroupStyledWrapper ${classes}`}>
          <HeadingElement className="title" headingLevel={headingLevel}>
            {title}
          </HeadingElement>
          <List className="linkGroupsList">
            <LinkGroup
              key={groupList.id}
              title={title}
              titleVisible={false}
              links={groupList}
              displayStyle="block"
              headingLevel={headingLevel + 1}
              className="linkGroup"
            />
          </List>
        </StyledWrapper>
      );
    }
    groups = groupList.map(({ id, title, items }) => {
      return (
        <LinkGroup
          key={id}
          title={title}
          titleVisible
          links={items}
          displayStyle="block"
          headingLevel={headingLevel}
        />
      );
    });
  }
  return (
    <StyledWrapper className={classes}>
      <HeadingElement className="title" headingLevel={headingLevel}>
        {title}
      </HeadingElement>
      {groups && <List className="groupsListWrapper">{groups}</List>}
    </StyledWrapper>
  );
};

LinkGroups.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  groupList: PropTypes.array.isRequired,
  headingLevel: PropTypes.number.isRequired,
};

LinkGroups.defaultProps = {
  className: '',
};

export default LinkGroups;
