import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

import GridList from '../core/GridList';

export const FeaturedBrandsWrapper = styled.section`
  margin-bottom: 3.5rem;
  &.microBrands {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: Calc(100% - 124px);
    margin-right: auto;
    margin-left: auto;
    h2 {
      margin: 0;
      margin-right: 50px;

      @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
        font-size: 1.25rem;
        line-height: 1.875rem;
        margin-left: 1rem;
      }
    }
    .microBrandTitle {
      min-width: 350px;
    }
    .microBrandIconList {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      overflow: hidden;
      width: 100%;
      justify-content: space-evenly;
      align-items: center;
    }
    @media only screen and (max-width: ${themeGet('breakpoints.xl')}) {
      flex-wrap: wrap;
    }
    @media only screen and (max-width: ${themeGet('breakpoints.lg')}) {
      .microBrandIconList {
        flex-wrap: wrap;
      }
    }
    @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
      display: inline-block;
      .microBrandIconList {
        flex-wrap: nowrap;
        justify-content: flex-start;
      }
      width: 100%;
      h3 {
        width: 100%;
      }
      .microBrandTitle {
        margin-bottom: 2rem;
      }
      .microBrandIconList {
        overflow-x: visible;
        width: 100%; /*This line is super sus - changed from Calc(100vw - 2 rem) - probably has consequences; can't render to see*/
        padding-bottom: 1rem;
      }

      .microBrandIconList::-webkit-scrollbar {
        -webkit-appearance: none;
      }

      .microBrandIconList::-webkit-scrollbar:horizontal {
        height: 11px;
      }

      .microBrandIconList::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: 4px solid white; /* should match background, can't be transparent */
        background-color: ${themeGet('colors.spinDark')};
      }
    }
  }
`;
export const FeaturedBrandsGridList = styled(GridList)``;
export const BrandItem = styled.div`
  width: 100%;
  max-width: Calc(100vw - 10px);
  text-align: right;
  * {
    font-family: ${themeGet('fonts.sans')};
  }
  h2,
  h3,
  h4 {
    line-height: 1.3125rem;
    font-size: 1.3125rem;
    font-weight: 600;
    letter-spacing: -0.5px;
    margin: 0;
    padding: 0;
  }
  .imageWithLogo {
    margin-bottom: 10px;
    .imageWithLogoImage {
      img {
        object-position: left center !important;
      }
    }
  }
  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    max-width: 100%;
    width: Calc(100vw - 32px);
    .imageWithLogo {
      margin-bottom: 7.34px;
    }
    h2,
    h3,
    h4 {
      line-height: 1.625rem;
      font-size: 1.3125rem;
    }
  }
`;
export const BrandItemMicro = styled.div`
  width: 160px;
  height: 160px;
  margin-right: 2.375rem;
  min-width: 80px;
  a {
    .gatsby-image-wrapper {
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain !important;
        object-position: center center !important;
        display: inline-block;
      }
    }
  }
  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    width: 8rem;
    height: 8rem;
    margin-right: 2rem;
    flex-shrink: 0;
  }
`;
