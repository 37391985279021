import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

import Link from '../core/Link';

export const StyledLink = styled(Link)`
  display: inline-block;
  color: ${themeGet('colors.spinDark')};
  font-family: Helvetica;
  font-size: 1rem;  
  letter-spacing: 0.44px;
`;

export const StyledStockPreview = styled.div`
  margin: 2rem 0 0 2rem;
  h2 {
    letter-spacing: 0;
    color: ${themeGet('colors.spinTextDark')};
    margin: 0;
    font-family: Rubik;
    font-size: 1.25rem;
    line-height: 1.25rem;
    font-weight: 900;
  }
  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    margin-left: 1rem;
  }
`;

export const StockInfoSection = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 720px) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    & > nav {
      margin-left: 1rem;
      align-items: start;
    }
  }
`;

export const StyledLinks = styled.nav`
  display: flex;

  @media only screen and (max-width: 720px) {
    margin-top: 30px;
  }

  @media only screen and (max-width: 530px) {
    flex-direction: column;
  }

  a {
    margin-left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.brand-blue {
      @media only screen and (max-width: 1155px) {
        padding: 17px 27px;
        font-size: 0.88rem;
      }
      @media only screen and (max-width: 1014px) {
        height: 70px;
      }
      @media only screen and (max-width: 796px) {
        padding: 17px 0;
      }
      @media only screen and (max-width: 720px) {
        padding: 14px 20px;
        height: 60px;
      }
      @media only screen and (max-width: 530px) {
        height: 45px;
        margin-left: 0;
        margin-bottom: 20px;
        padding: 14px 27px;
      }
    }
    &.brand-blue:first-of-type {
      @media only screen and (max-width: 720px) {
        margin-left: 0;
      }
    }
  }
`;
