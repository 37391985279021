import React from 'react';

import StockPreview from '../StockPreview';
import Title from '../core/Title';

import { StyledStockInformation } from './styles';

const StockInformation = ({ title, description }) => {
  return (
    <StyledStockInformation>
      <Title title={title} />
      <StockPreview fullView />
    </StyledStockInformation>
  );
};

export default StockInformation;
