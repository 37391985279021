import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { PriceInfo, QToolMinimal, QToolDetailed } from './styles';

/**
 * Represents a preview of stock data
 * @param {boolean} fullView links - required
 */
const StockPreview = ({ fullView }) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://qmod.quotemedia.com/js/qmodLoader.js';
    script.setAttribute('data-qmod-wmid', '102286');
    script.setAttribute('data-qmod-tool', 'detailedquotetabchartnews');
    script.id = 'qmod';
    script.type = 'application/javascript';
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <PriceInfo className="priceInfo">
      {!fullView ? (
        <QToolMinimal
          data-qmod-tool="detailedquotetab"
          data-qmod-params='{ "symbol" : "TOY:CA" }'
          className="qtool"
        />
      ) : (
        <QToolDetailed
          data-qmod-tool="detailedquotetab"
          data-qmod-params='{ "symbol" : "TOY:CA" }'
          className="qtool"
        />
      )}
    </PriceInfo>
  );
};

StockPreview.propTypes = {
  fullView: PropTypes.bool.isRequired,
};

export default StockPreview;
