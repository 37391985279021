import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const StyledInputField = styled.label`
  display: block;
  color: ${themeGet('colors.spinTextDark')};

  input {
    box-sizing: border-box;
    height: 45px;
    width: 100%;
    border-radius: 22.5px;
    background-color: #f2f2f2;
    margin: 6px 0;
    padding-left: 10px;
    &.invalid {
      border: 1px solid #f92222;
    }
  }
`;

export const ErrorMessage = styled.span`
  color: #e30606;
`;
