import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import ArticlePreviewList from '../core/ArticlePreviewList';
import withFilter from '../../higherOrderComponents/withFilter';
import LinkList from '../core/LinkList';

import { StyledReleases, SubscribeSection, StyledReleasesWrap } from './styles';
import HeadingElement from '../core/HeadingElement';
import { graphql, useStaticQuery } from 'gatsby';

const PressReleases = (props) => {
  const {
    components,
    title,
    ctaReference,
    filterExists,
    hasShowAll,
    translations,
    columns,
    headingLevel,
    pathname,
  } = props;
  function findComponent(componentType) {
    return components.find((item) => item.componentStyle === componentType);
  }
  let pressReleases;

  const { allPressRelease } = useStaticQuery(graphql`
    query TopReleases {
      allPressRelease(sort: {fields: id, order: DESC}) {
        nodes {
          id
          headline
          modifiedDate
          releaseDate
          released
          tag
        }
      }
    }
  `);
  if(pathname.includes("press-releases")) {
    pressReleases = allPressRelease.nodes;
  } else {
    pressReleases = allPressRelease.nodes.slice(0,3);
  }
  const ReleasesList = withFilter(ArticlePreviewList);

  var column = columns.length > 1? columns.slice(0, 1) : columns;
  const columnStyle = `sectionColumns_${column}`;

  const form = findComponent('email-form');
  if (form) {
    const { cardTitle, description } = form;
    const ctas = form.components || [];

    return (
      <StyledReleases
        columns={column}
        className={`styledReleases ${columnStyle}`}
      >
        <StyledReleasesWrap className="styledReleasesWrap">
          <HeadingElement headingLevel={headingLevel}>
            {components[0].cardTitle}
          </HeadingElement>
          <ReleasesList
            className="releasesList"
            components={pressReleases}
            title=""
            filterExists={filterExists}
            hasShowAll={hasShowAll}
            translations={translations}
            headingLevel={headingLevel + 1} //+1 because these is a title already above
          />
          {ctaReference && !!ctaReference.length && (
            <LinkList
              links={ctaReference}
              ctaStyle="solid-on-dark-bg hvr-bounce-to-right"
            />
          )}
        </StyledReleasesWrap>
        <SubscribeSection className="subscribeSection">
          <HeadingElement headingLevel={headingLevel}>
            {cardTitle}
          </HeadingElement>
          <p>{description.description}</p>
          {ctas && (
            <LinkList
              links={ctas}
              ctaStyle="solid-on-dark-bg hvr-bounce-to-right"
            />
          )}
        </SubscribeSection>
      </StyledReleases>
    );
  }
  return (
    <StyledReleases
      columns={column}
      className={`styledReleases ${columnStyle}`}
    >
      <StyledReleasesWrap className="styledReleasesWrap">
        <ReleasesList
          components={pressReleases}
          title={title}
          filterExists={filterExists}
          hasShowAll={hasShowAll}
          pressReleasesCta={ctaReference}
          translations={translations}
          headingLevel={headingLevel}
        />
      </StyledReleasesWrap>
    </StyledReleases>
  );
};

PressReleases.propTypes = {
  components: PropTypes.array.isRequired,
  title: PropTypes.string,
  ctaReference: PropTypes.array,
  filterExists: PropTypes.bool,
};

PressReleases.defaultProps = {
  title: '',
  ctaReference: [],
  filterExists: false,
};

export default PressReleases;
