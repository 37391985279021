// Use a little helper function to remove trailing slashes from paths
export const removeTrailingSlash = (path) =>
  path === `/` ? path : path.replace(/\/$/, ``);

export const localizedSlug = ({ isDefault, locale, slug }) =>
  isDefault ? `/${slug}` : `/${locale}/${slug}`;

// From lodash:
// https://github.com/lodash/lodash/blob/750067f42d3aa5f927604ece2c6df0ff2b2e9d72/findKey.js
export const findKey = (object, predicate) => {
  let result;
  if (object == null) {
    return result;
  }
  Object.keys(object).some((key) => {
    const value = object[key];
    if (predicate(value, key, object)) {
      result = key;
      return true;
    }
    return false;
  });
  return result;
};

export const createLocalizedPages = (
  createPage,
  locales,
  page,
  siteConfig,
  sitemap,
) => {
  // console.log('PAGE PATH', page.path);
  // Grab the keys ('en' & 'de') of locales and map over them
  locales.map((locale) => {
    const localeTranslation = siteConfig.translations.filter((translation) => {
      if (!translation.copy[0]) {
        return false;
      }
      return translation.copy[0].locale === locale.code;
    });

    // Use the values defined in "locales" to construct the path

    const localizedPath = localizedSlug({
      isDefault: false,
      locale: locale.code,
      slug: page.path === 'home' ? '/' : page.path,
    });

    const modifiedLocale = { ...locale, localeTranslation };

    if (locale.default && page.path.indexOf('404') === -1) {
      createPage({
        // Pass on everything from the original page
        ...page,
        // Since page.path returns with a trailing slash (e.g. "/de/")
        path: removeTrailingSlash(page.path),
        // Pass in the locale as context to every page
        // This context also gets passed to the src/components/layout file
        // This should ensure that the locale is available on every page
        // TODO: add canonical info here
        context: {
          ...page.context,
          siteConfiguration: siteConfig,
          locale: modifiedLocale,
          locales,
          sitemap,
        },
      });
    }

    const matchPath =
      page.path.indexOf('404') !== -1 ? { matchPath: `/${locale.code}/*` } : {};
    // console.log('MATCH PATH', matchPath);
    return createPage({
      // Pass on everything from the original page
      ...page,
      ...matchPath,
      // Since page.path returns with a trailing slash (e.g. "/de/")
      path: removeTrailingSlash(localizedPath),
      // Pass in the locale as context to every page
      // This context also gets passed to the src/components/layout file
      // This should ensure that the locale is available on every page
      context: {
        ...page.context,
        locale: modifiedLocale,
        locales,
        siteConfiguration: siteConfig,
        sitemap,
      },
    });
  });
};
