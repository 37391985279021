import PropTypes from 'prop-types';
import React from 'react';

import { Btn } from './styles';

// Style options
// {children} renders within the button as text
// {customAction} is the onClick method
// {customStyle} is an array of classes
const Button = ({ id, className, children, onClick, disabled, type }) => {
  return (
    <Btn
      id={id}
      className={className}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Btn>
  );
};

// Prop types
Button.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

// Default props
Button.defaultProps = {
  id: null,
  className: 'solid-on-light-bg',
  type: 'button',
  onClick: null,
  disabled: false,
};

export default Button;
