import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Card from '../Card';
import accordionMinus from '../../../images/accordionMinus.svg';
import accordionPlus from '../../../images/accordionPlus.svg';
import LinkList from '../LinkList';
import Article from '../Article';
import HeadingElement from '../HeadingElement';

import {
  AccordionWrapper,
  AccordionItem,
  AccordionHandle,
  AccordionDetails,
  AccordionTop,
} from './styles';

const Accordion = ({
  title,
  expanded = false,
  components,
  ctaReference,
  headingLevel,
  ...props
}) => {
  const initStateVal = [...Array(components.length)].map(
    (x, index) => index === 0 || expanded
  );
  const [active, setStateActive] = useState(initStateVal);
  const toggleState = (index) => {
    let duplicateArray;
    duplicateArray = [...active];
    duplicateArray[index] = !active[index];
    setStateActive(duplicateArray);
  };
  const clickToggle = (index) => {
    toggleState(index);
  };
  const isObject = obj => {
    return Object.prototype.toString.call(obj) === '[object Object]'
  }
  const isRichTextComponent = (component, componentStyle) => {
    const content =   isObject(component.components?.content) ? JSON.parse(component.components.content.raw): '';
    return componentStyle === 'rich-text' && !!content;
  };

  const isRawTextComponent = (componentStyle) => componentStyle === 'rich-text';

  const getAccordionContent = (
    component,
    index,
    componentStyle,
    contentHeadingLevel
  ) => {
    // used in "patents" page
    if (isRichTextComponent(component, componentStyle)) {
      return (
        <Article
          components={[{ components: component.components }]}
          className="accordion-article"
        />
      );
    }
    // used in "customer-support" page
    if (isRawTextComponent(componentStyle)) {
      return (
        <Card
          {...component}
          key={component.id}
          content={component.listComponents ? component.listComponents[0].content : component.components ? component.components.rawText : null}
          columns={1}
          bkgColour="transparent"
          title={null}
          onClick={() => {
            clickToggle(index);
          }}
          headingLevel={contentHeadingLevel}
        />
      );
    }
    // used in "corporate-governance", "regulatory-documents" pages
    return (
      <LinkList
        links={component.listComponents}
        className="accordion-links "
        ctaStyle="externalStyledLink"
      />
    );
  };
  return (
    <>
      <AccordionWrapper className="accordionWrapper">
        {title && (
          <HeadingElement
            className="accordion-title"
            headingLevel={headingLevel}
          >
            {title}
          </HeadingElement>
        )}
        {components.map((component, index) => {
          const { id, componentStyle } = component;
          let contentHeadingLevel = headingLevel;
          if (title && component.cardTitle) {
            contentHeadingLevel = headingLevel + 2;
          } else if (title || component.cardTitle) {
            contentHeadingLevel = headingLevel + 1;
          }
          const accordionContent = getAccordionContent(
            component,
            index,
            componentStyle,
            contentHeadingLevel
          );

          return (
            <AccordionItem
              key={id}
              className={`accordionItem accordionItem${
                active[index] ? ' active' : ' collapsed'
              }`}
            >
              {component.cardTitle && (
                <AccordionTop
                  className="accordion-top"
                  onClick={() => {
                    clickToggle(index);
                  }}
                >
                  <AccordionHandle>
                    <img
                      alt=""
                      src={accordionMinus}
                      className="arrowDown handleIndicator"
                    />
                    <img
                      alt=""
                      src={accordionPlus}
                      className="arrowUp handleIndicator"
                    />
                  </AccordionHandle>
                  <HeadingElement
                    className="accordion-item-title"
                    headingLevel={title ? headingLevel + 1 : headingLevel}
                  >
                    {component.cardTitle}
                  </HeadingElement>
                </AccordionTop>
              )}
              <AccordionDetails className="details" key={component.cardTitle}>
                {accordionContent}
              </AccordionDetails>
            </AccordionItem>
          );
        })}
      </AccordionWrapper>
      {ctaReference && !!ctaReference.length && (
        <LinkList
          links={ctaReference}
          ctaStyle="solid-on-dark-bg hvr-bounce-to-right"
        />
      )}
    </>
  );
};
Accordion.propTypes = {};

Accordion.defaultProps = {};
export default Accordion;
