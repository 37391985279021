import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import Link from '../Link';
import { LocaleProvider } from '../Context/LocaleProvider';
import { getLocalizedLink, getPathFromSlug } from '../../../utils/helpers';
import { SitemapProvider } from '../Context/SitemapProvider';

import { StyledArticlePreview, Description } from './styles';

const ArticlePreview = ({ id, title, description }) => {
  const locale = useContext(LocaleProvider);
  //const sitemap = useContext(SitemapProvider);
  //const { path } = getPathFromSlug(sitemap.pages, 'press-releases', locale);
  const path = 'corporate/media/press-releases';

  return (
    <StyledArticlePreview>
      <Link to={`/${locale.code}/${path}/${id}`} children={title} />
      <Description>{description}</Description>
    </StyledArticlePreview>
  );
};
ArticlePreview.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default ArticlePreview;
