import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const StyledTextAreaField = styled.label`
  display: block;
  color: ${themeGet('colors.spinTextDark')};

  textarea {
    box-sizing: border-box;
    width: 100%;
    height: 200px;
    border-radius: 5px;
    background-color: #f2f2f2;
    margin: 6px 0;
    padding: 1rem 2.5rem;
    border: 1px solid ${themeGet('colors.formInputBorder')};
    &.invalid {
      border: 1px solid #f92222;
    }
  }
`;

export const ErrorMessage = styled.span`
  color: #e30606;
`;
