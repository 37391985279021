import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const StyledUploadField = styled.div`
  display: block;
  color: ${themeGet('colors.spinTextDark')};
  position: relative;
  min-height: 200px;
  background: ${themeGet('colors.spinSecondaryFour')};
  border: 3px dotted darkgrey;
  border-radius: 20px;
  padding: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    font-size: 1rem;
    width: 100%;
  }
`;
export const ClearUploadButton = styled.div`
  color: ${themeGet('colors.spinTextDark')};
  margin-top: 1rem;
  text-decoration: underline;
  cursor: pointer;
`;

export const ErrorMessage = styled.span`
  color: #e30606;
`;

export const ThumbsContainer = styled.aside`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 16px;
  position: absolute;
  top: 0;
  left: 0;
`;

export const Thumb = styled.div`
  display: inline-flex;
  border-radius: 2;
  border: 1px solid #eaeaea;
  margin-bottom: 8;
  margin-right: 8;
  width: 100px;
  height: 100px;
  padding: 4px;
  box-sizing: border-box;
`;

export const ThumbInner = styled.div`
  display: flex;
  min-width: 0;
  overflow: hidden;
  img {
    display: block;
    width: auto;
    height: 100%;
  }
`;
