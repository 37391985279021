import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const StyledForm = styled.form`
  max-width: ${themeGet('article.narrow.maxWidth')};
  background: ${themeGet('colors.spinSecondaryFour')};
  padding: 20px;
  margin: 0 auto;
  select {
  }
  input {
    padding: 0 2.5rem;
    border: 1px solid ${themeGet('colors.formInputBorder')};
    margin-bottom: 2.375rem;
    box-sizing: border-box;
    &::placeholder {
      overflow: visible;
    }
    &::-moz-placeholder {
      line-height: 2.625rem;
    }
  }
  input,
  textarea {
    &::placeholder {
      color: ${themeGet('colors.spinDark')};
      opacity: 0.75;
    }
  }
`;

export const Message = styled.p`
  &.error {
    color: ${themeGet('colors.spinAlert')};
  }
`;

export const FormSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    flex-direction: column;
  }
`;

export const SectionHeadingWrapper = styled.div`
  min-width: 200px;
  width: 200px;
  padding-right: 20px;
  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    width: 100%;
  }
`;
export const FieldWrapper = styled.div`
  flex: 1 1 auto;
  &.two-column {
    > div {
      flex-direction: column;
      width: 50%;
      display: inline-flex;
    }
    > div:nth-child(odd) {
      padding-right: 20px;
    }
  }
  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    width: 100%;
    &.two-column {
      > div {
        width: 100%;
      }
      > div:nth-child(odd) {
        padding-right: 0;
      }
    }
  }
`;

export const Fieldset = styled.div`
  width: 100%;

  label,
  input,
  .autocomplete,
  .dropzone {
    width: 100%;
  }
  .autocomplete {
    border-radius: 22.5px;
    background-color: #f2f2f2;
    margin-top: 6px;
    margin-left: 0;
    margin-right: 0;
    height: 45px;
    color: rgba(0, 22, 138, 0.75);
  }
`;

export const RippleLoading = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  left: 50%;
  transform: translateX(-40px);

  div {
    position: absolute;
    border: 4px solid ${themeGet('colors.spinTextDark')};
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    &:nth-child(2) {
      animation-delay: -0.5s;
    }
  }

  @keyframes lds-ripple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }
`;
