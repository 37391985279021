import styled from 'styled-components';

import Image from '../core/Image';

export const StyledImage = styled(Image)`
  margin-right: 20px;
`;

export const StyledColumn = styled.span`
  display: flex;
  align-items: center;
`;

export const StyledCommitteeSection = styled.section`
  margin-bottom: 84px;
  .styledLinkList {
    margin-top: 10px;
  }
  h2 {
    margin-left: 2rem;
  }
`;
