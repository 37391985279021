import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const MediaSection3DWrapper = styled.section`
  background-color: ${themeGet('colors.spinSecondaryFour')};
  padding: 2rem;
  border-radius: 5px;
  .card {
    &.cardColor_transparent {
      .card-details-block {
        padding: 0;
        .card-details {
          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            &:first-child {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
  .styledArticle {
    margin: 0 auto;
    &.narrow {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
  & > h3 {
    margin-bottom: 2.75rem;
  }
  & > article:first-child {
    background: transparent;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
    padding: 0;
  }
  & > section,
  & > article,
  div.accordion-links {
    margin-bottom: 2rem;
    background-color: ${themeGet('colors.white')};
    padding: 2rem;
    border-radius: 5px;
  }

  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    padding: 1rem;
    h2 {
      margin-top: 0;
    }
    .rolodex {
      padding: 1rem;
    }
  }
`;

export const GroupWrapper = styled.div`
  background-color: ${themeGet('colors.white')};
  border-radius: 5px;
  padding: 2rem;
  margin: 0rem auto 2rem;
  &.componentTypeRolodex {
    h2:first-child {
      margin-top: 0;
    }
  }
`;
