import styled from 'styled-components';

export const TopRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const TableWrapper = styled.div`
  display: flex;
  flex-grow: 1;
`;

export const StyledStockInformation = styled.section`
  padding: 25px;
`;
