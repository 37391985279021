import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const PriceInfo = styled.div`
  display: flex;
`;

export const QToolDetailed = styled.div`
  padding: 10px 25px;
  width: 100%;
`;

export const QToolMinimal = styled.div`
  .qmod-block-wrapper {
    background-color: ${themeGet('colors.whiteSnow')};
  }
  .qmod-block-wrapper:nth-child(2) {
    display: none;
  }

  .qmod-heading {
    display: none;
  }

  .qmod-head-left .qmod-mkt-mid,
  .qmod-head-left .qmod-mkt-btm,
  .qmod-head-left .qmod-entline {
    /* display: none; */
  }

  .qmod-head-left .qmod-mkt-top .qmod-last {
    margin: 0;
    font-family: 'Open Sans';
    font-size: 50px !important;
    color: ${themeGet('colors.SpinTextDark')};
    font-weight: 800;
    line-height: 45px;
  }

  .qtool .qmod-head-left {
    margin: 0 !important;
  }

  .qmod-detailedquote .qmod-quotehead .qmod-head-left {
    margin-left: 0;
  }

  .qmod-detailedquote .fa-arrow-circle-up:before,
  .qmod-pipe-sep,
  .qmod-detailedquote .fa-arrow-circle-down:before {
    /* display: none; */
  }

  .qmod-change {
    position: relative;
    top: -10px;
    left: 10px;
  }

  .qmod-change span:nth-child(2),
  .qmod-change span:nth-child(4) {
    font-size: 16px;
    line-height: 26px;
    font-family: 'Open Sans';
    color: ${themeGet('colors.SpinTextDark')};
  }

  .qmod-change {
    position: relative;
  }

  .qmod-change span:nth-child(4):before {
    content: ' (';
  }

  .qmod-change span:nth-child(4):after {
    content: ')';
  }
`;
