import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import LinkList from '../LinkList';
import { LocaleProvider } from '../Context/LocaleProvider';
import RichText from '../RichText';
import HeadingElement from '../HeadingElement';

import { StyledArticle } from './styles';

/**
 * Represents an Article.
 * @param {array} items required content of the article
 * @example
 *[{
 *	"title": "About Spin Master",
 *	"items": [{
 *		"id": "5REVZw7LHvCy93MQ64ViQ0",
 *		"content": {
 *			"data": {},
 *			"content": [{
 *				"data": {},
 *				"content": [{
 *					"data": {},
 *					"marks": [{
 *						"type": "italic"
 *					}],
 *					"value": "PAW Patrol",
 *					"nodeType": "text"
 *				}],
 *				"nodeType": "paragraph"
 *			}],
 *			"nodeType": "document"
 *		}
 *	}],
 * }]
 */
const Article = ({
  components,
  ctaReference,
  title,
  contentType,
  className,
  headingLevel,
}) => {
  if (!components) {
    return;
  }
  const locale = useContext(LocaleProvider);
  const styleVariant = contentType === 'Text Block Wide' ? 'wide' : '';

  const widths = {
    NARROW: 'narrow',
    DEFAULT: 'default',
  };
  const textBlocks = _.get(components, '[0].components');
  const textBlockList = _.get(components, '[1].listComponents') || _.get(components, '[0].listComponents');
  let textBlockElements;
  if (textBlockList && textBlockList.length) {
    textBlockElements = textBlockList.map((block) => {
      const { textBlockTitle, content, id } = block;
      return (
        <div key={id} className="spinmaster-article">
          {textBlockTitle && <RichText richTextContent={JSON.parse(textBlockTitle.raw)} />}
          {content && <RichText richTextContent={JSON.parse(content.raw)} />}
        </div>
      );
    });
  }
  const textBlockWidth = _.get(components, '[0].components.textBlockWidth');
  const widthClass =
    textBlockWidth && textBlockWidth.toLowerCase() === widths.NARROW
      ? widths.NARROW
      : widths.DEFAULT;
  return (
    <StyledArticle className={`styledArticle ${widthClass} ${className}`}>
      {title && (
        <HeadingElement headingLevel={headingLevel}>{title}</HeadingElement>
      )}
      {textBlockElements && textBlockElements}
      {!textBlockElements && textBlocks && (
        <div key={textBlocks.id} className="spinmaster-article">
         {textBlocks.textBlockTitle && <RichText richTextContent={JSON.parse(textBlocks.textBlockTitle.raw)} />}
          {textBlocks.content && <RichText richTextContent={textBlocks.content} />}
        </div>
      )}
      {ctaReference && !!ctaReference.length && (
        <LinkList
          links={ctaReference}
          ctaStyle="solid-on-dark-bg hvr-bounce-to-right"
        />
      )}
    </StyledArticle>
  );
};

Article.defaultProps = {
  ctaReference: [],
  title: '',
  className: '',
  headingLevel: '',
};

Article.propTypes = {
  components: PropTypes.array.isRequired,
  ctaReference: PropTypes.array,
  title: PropTypes.string,
  className: PropTypes.string,
  headingLevel: PropTypes.number,
};

export default Article;
