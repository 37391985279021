import React from 'react';
import PropTypes from 'prop-types';

import { StyledIframe } from './styles';

/**
 * Represents an Iframe.
 * @param {string} src required - Page url used inside the iframe.
 */
const Iframe = ({ src }) => <StyledIframe src={src} />;

Iframe.propTypes = {
  src: PropTypes.string.isRequired,
};

export default Iframe;
