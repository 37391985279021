import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import Image from '../Image';
import externalLinkIcon from '../../../images/exit.svg';
import LinkWithImage from '../LinkWithImage';
import { LocaleProvider } from '../Context/LocaleProvider';
import { getLinkUrl } from '../../../utils/helpers';
import Link from '../Link';
import HeadingElement from '../HeadingElement';
import {
  StyledCard,
  Info,
  AbsoluteRatio,
  RolodexIndex,
  CardDetails,
  RightSide,
} from './styles';

/**
 * Represents an Image.
 * @param {string} title required title
 * @param {array} links [optional=[]] - represents links on the card
 * @param {string} image required - image
 * @param {object} imageSharp [optional={}] - image generated by imageSharp
 */
const RolodexCard = (props) => {
  const {
    title,
    linkURL,
    image,
    imageSharp,
    className,
    rolodexIndex,
    ariaLabel,
    links,
    showTitle,
    headingLevel,
  } = props;
  const locale = useContext(LocaleProvider);
  const linkBlock = links
    ? links.map((link) => {
        const url = getLinkUrl(link, locale);
        if (url) {
          return (
            <LinkWithImage
              key={link.id}
              linkURL={url}
              imageSrc={link.externalLink ? externalLinkIcon : null}
              altText={ariaLabel}
            >
              <div className="h4">{link.textLink}</div>
            </LinkWithImage>
          );
        }
        return null;
      })
    : null;

  return (
    <StyledCard className={`card ${className || ''}`}>
      <Info className="card-details-block">
        <RightSide className="rolodexIndexWrapper">
          <RolodexIndex className="fixed-width rolodexIndex">
            {rolodexIndex && (
              <HeadingElement
                headingLevel={headingLevel}
                className="rolodex-card-index"
              >
                {rolodexIndex}
              </HeadingElement>
            )}
          </RolodexIndex>
          <>
            <AbsoluteRatio className="absoluteRatio fixed-width">
              {(image ||
                (imageSharp && Object.keys(imageSharp).length > 0)) && (
                <>
                  <Image src={image} alt={title} imageSharp={imageSharp} />
                </>
              )}
            </AbsoluteRatio>
            {showTitle && (
              <HeadingElement
                className="rolodex-card-title"
                // it doesn't matter if rolodexIndex exists or not on this
                // particular card, heading must be one level lower
                headingLevel={headingLevel + 1}
              >
                {title}
              </HeadingElement>
            )}
          </>
        </RightSide>
        {(linkURL || linkBlock) && (
          <CardDetails className="cardDetails">
            {linkURL && (
              <LinkWithImage
                linkURL={linkURL}
                imageSrc={externalLinkIcon}
                altText={ariaLabel}
              >
                <div className="h4">{title}</div>
              </LinkWithImage>
            )}
            {linkBlock}
          </CardDetails>
        )}
      </Info>
    </StyledCard>
  );
};

RolodexCard.propTypes = {
  title: PropTypes.string.isRequired,
  linkURL: PropTypes.string,
  image: PropTypes.string,
  rolodexIndex: PropTypes.string,
  imageSharp: PropTypes.object,
  ariaLabel: PropTypes.string.isRequired,
};

RolodexCard.defaultProps = {
  linkURL: '',
  imageSharp: {},
  image: '',
  rolodexIndex: '',
};

export default RolodexCard;
