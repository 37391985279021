import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { LocaleProvider } from '../core/Context/LocaleProvider';
import { getTranslation, createSlug } from '../../utils/helpers';
import LinkList from '../core/LinkList';
import Table from '../core/Table';
import Link from '../core/Link';
import HeadingElement from '../core/HeadingElement';

import { StyledImage, StyledColumn, StyledCommitteeSection } from './styles';

/**
 * Represents a list of ProductAccordion components.
 * @param {string} title required section title
 * @param {number} headingLevel required headingLevel
 * @param {array} items required a list of profiles
 * @param {array} translations required
 * @param {array} ctaReference optional=[] a list of ctas
 */
const CorporateGovernanceTable = (props) => {
  const locale = useContext(LocaleProvider).code;
  const {
    title,
    components,
    translations,
    ctaReference,
    icons,
    headingLevel,
  } = props;

  const checkboxSrc = _.get(icons, 'file.url');
  const checkboxImageSharp = _.get(icons, 'gatsbyImage');
  const checkboxAlt =
    _.get(icons, 'description') ||
    _.get(icons, 'title') ||
    '';

  const getAllCommittees = (profiles) => {
    let allCommittees = [];
    profiles.forEach((profile) => {
      if (profile.identifier) {
        const committees = profile.identifier.map(
          (tag) => tag.MembershipInCommittee,
        );
        allCommittees = [...allCommittees, ...committees];
      }
    });
    return allCommittees;
  };

  const getProfileLink = (profileName) => {
    const slug = `/${locale}/corporate/leadership/${createSlug(
      profileName,
    )}`;
    return (
      <Link
        key={profileName}
        ariaLabel={profileName}
        children={profileName}
        to={slug}
      />
    );
  };
  const formRow = (profile, headers) => {
    let columns = [];
    headers.forEach((headerItem) => {
      if (profile.identifier) {
        const columnData = profile.identifier.find(
          (tag) => _.get(tag, 'MembershipInCommittee') === headerItem,
        );
        if (columnData) {
          columns = [
            ...columns,
            <StyledColumn>
              <StyledImage
                src={checkboxSrc}
                alt={checkboxAlt}
                imageSharp={checkboxImageSharp}
              />
              {columnData.role && <span>{columnData.role}</span>}
            </StyledColumn>,
          ];
        } else {
          columns = [...columns, <StyledColumn />];
        }
      } else {
        columns = [...columns, <StyledColumn />];
      }
    });
    const row = [getProfileLink(profile.profileName), ...columns];
    return row;
  };

  const formRows = (profiles, committees) =>
    profiles.map((profile) => formRow(profile, committees));

  // filter empty profiles
  const profileList = components.filter((profile) => !!Object.keys(profile).length);
  const committees = Array.from(new Set(getAllCommittees(profileList)));
  const committeesSortedByLength = committees.sort(
    (a, b) => a.length - b.length,
  );
  const rows = formRows(profileList, committeesSortedByLength);

  const directorHeading = getTranslation(
    'director',
    translations,
    locale);

  return (
    <StyledCommitteeSection className="styledCommitteeSection">
      <HeadingElement headingLevel={headingLevel}>{title}</HeadingElement>
      <Table
        caption=""
        headerLabels={[directorHeading, ...committeesSortedByLength]}
        items={rows}
        style="topHeader"
      />
      {ctaReference && !!ctaReference.length && (
        <LinkList
          links={ctaReference}
          ctaStyle="solid-on-dark-bg hvr-bounce-to-right"
        />
      )}
    </StyledCommitteeSection>
  );
};

CorporateGovernanceTable.propTypes = {
  title: PropTypes.string.isRequired,
  headingLevel: PropTypes.number.isRequired,
  components: PropTypes.array.isRequired,
  translations: PropTypes.array.isRequired,
  ctaReference: PropTypes.array,
};

CorporateGovernanceTable.defaultProps = {
  ctaReference: [],
};

export default CorporateGovernanceTable;
