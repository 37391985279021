import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
  getTranslation,
  createSlug,
  getPathFromSlug,
  getPatternCardStyle,
  sortByOrder,
} from '../../utils/helpers';
import { LocaleProvider } from '../core/Context/LocaleProvider';
import { SitemapProvider } from '../core/Context/SitemapProvider';
import Card from '../core/Card';
import { CardsWrapper } from '../CardsBlock/styles';

/**
 * Represents a list of Card components.
 * @param {array} items required list of Profile objects
 * @param {array} heading required heading level
 * @param {array} translations [optional=[]] list of translatable items
 * @param {number} columns [optional=3] - how many items should fit on screen
 * @param {boolean} horizontalScroll [optional=true] - should scroll using horizontal scroll
 */
const Leadership = ({
  components,
  columns,
  horizontalScroll,
  translations,
  headingLevel,
}) => {
  columns = columns.slice(0, 1);
  const locale = useContext(LocaleProvider);
  const sitemap = useContext(SitemapProvider);
  const bioCopy = getTranslation('read_bio', translations, locale.code);

  const readBioText = bioCopy;
  if (components.length) {
    const cardElements = components.map((item, index) => {
      const { profileName, profileImage, directorsRole, jobTitle } = item;
      const slug = profileName
        ? `/${locale.code}/corporate/leadership/${createSlug(
          profileName
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, ''),
        )}`: '';
      const links = [
        {
          id: profileName,
          ariaLabel: `${profileName}: ${readBioText}`,
          textLink: readBioText,
          linkURL: slug,
        },
      ];

      const cardLook = getPatternCardStyle(index);
      return (
        <Card
          cardType={cardLook.cardType}
          key={`${profileName}-${index}`}
          title={profileName}
          description={directorsRole || jobTitle}
          bkgColour={cardLook.backgroundColor}
          links={links}
          image={profileImage}
          imageSharp={profileImage.gatsbyImage}
          headingLevel={headingLevel}
        />
      );
    });
    const cssStyle = horizontalScroll ? 'horizontal-scroll' : '';
    return (
      <CardsWrapper className={cssStyle} columns={columns}>
        {cardElements}
      </CardsWrapper>
    );
  }
  return null;
};

Leadership.propTypes = {
  components: PropTypes.array.isRequired,
  columns: PropTypes.string,
  horizontalScroll: PropTypes.bool,
  ctaReference: PropTypes.array,
  translations: PropTypes.array,
  heading: PropTypes.number.isRequired,
};

Leadership.defaultProps = {
  columns: 3,
  horizontalScroll: false,
  ctaReference: [],
  translations: [],
};

export default Leadership;
