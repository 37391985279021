import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const StyledArticleList = styled.article`
  background-color: ${themeGet('colors.spinSecondaryFour')};
  padding: 2rem;
  border-radius: 5px;
  p {
    margin-bottom: 25px;
  }

  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    padding: 1rem;
  }
`;
