import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Image from '../core/Image';
import Article from '../core/Article';
import LinkList from '../core/LinkList';

import { StyledProfile } from './styles';

const Profile = ({
  profileName,
  directorsRole,
  jobTitle,
  biography,
  profileImage,
  ctaReference,
}) => {
  const title = directorsRole || jobTitle;
  const imageSharp = _.get(profileImage, 'gatsbyImage');
  const imageSrc = _.get(profileImage, 'file.url');
  const imageAlt =
    _.get(profileImage, 'description') || _.get(profileImage, 'title') || '';
  const textBlocks = [
    {
      components: { id: profileName, content: JSON.parse(biography.raw) },
    },
  ];

  return (
    <StyledProfile className="styledProfile">
      <h1>{profileName}</h1>
      {title && <h2>{title}</h2>}
      <Image src={imageSrc} alt={imageAlt} imageSharp={imageSharp} />
      <div>
        {biography && <Article components={textBlocks} />}
        {ctaReference && !!ctaReference.length && (
          <LinkList
            links={ctaReference}
            ctaStyle="solid-on-dark-bg hvr-bounce-to-right"
          />
        )}
      </div>
    </StyledProfile>
  );
};

Profile.propTypes = {
  profileName: PropTypes.string.isRequired,
  directorsRole: PropTypes.string,
  jobTitle: PropTypes.string,
  biography: PropTypes.object,
  imageProfile: PropTypes.object,
  ctaReference: PropTypes.array,
};

Profile.propTypes = {
  directorsRole: '',
  jobTitle: '',
  biography: null,
  imageProfile: {},
  ctaReference: [],
};

export default Profile;
