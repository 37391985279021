import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const StyledCard = styled.div`
  width: 100%;
  background-color: ${(props) => props.backgroundColor};
  border-radius: 5px;
  /* border: 1px solid ${themeGet('colors.formInputBorder')}; */
  ${(props) => (props.backgroundColor == 'transparent' ? 'border:none;' : '')}

  overflow: hidden;
  /* &.image-tile-above-title { */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .card-details-block {
    flex-grow: 1;
  }
  /* } */
  &.pattern-card {
    .card-details-block {
      margin-bottom: 0;
      min-height: 0;
    }
    p {
      margin-bottom: 0;
      font-size: 1rem;
      font-weight: normal;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0 0 0.375rem 0;
    }
    a {
      margin: 0.9rem 0;
    }
  }
  &.pattern-card.white-card {
    border: 1px solid #cfcfcf;
  }
  &.pattern-card.no-border {
    border: none;
  }
  img {
    border-radius: 5px 5px 0 0;
    width: 100%;
  }
  p {
    ${themeGet('typeStyles.card.imageBehind.description')}
    color: ${themeGet('colors.spinTextDark')};
    letter-spacing: 0;
    font-weight: 600;
    font-family: ${themeGet('fonts.sans')};

    @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
      ${themeGet('typeStyles.card.imageBehind.mobile.description')}
    }
  }
  &.factoid {
    overflow: visible;
    .card-details-block {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-size: 3.75rem;
        line-height: 3.4375rem;
        overflow-wrap: break-word;
      }
      p {
        font-size: 1rem;
        line-height: 1.625rem;
      }

      @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
        padding-top: 0;
        padding-bottom: 0;
        .card-details {
          margin-bottom: 0;
        }
      }
    }
  }
  &.image-behind-text,
  &.default.noImage {
    position: relative;
    .absoluteRatio,
    .card-details-block {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
    }
    .absoluteRatio {
      position: absolute;
      z-index: 0;
      object-fit: cover;
    }
    .card-details-block {
      position: relative;
      z-index: 1;
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        ${themeGet('typeStyles.card.imageBehind.heading')}
        margin-top: 0rem; /* altered to match homepage designs - this will definitely bite me in the butt*/
      }
    }

    @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
      min-height: 230px;

      .card-details-block {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-size: 1.875rem;
          line-height: 2.1875rem;
          margin-top: 0; /* altered to match homepage designs*/
        }
      }
    }
  }
  &.default.noImage {
    h3:first-child {
      margin-top: 0;
    }
  }

  &.showcase-link {
    text-align: center;
    max-width: 890px;
    margin: 0 auto;
    .card-details {
      margin-bottom: 3rem;
      p {
        font-size: 1.5625rem;
        line-height: 2.5rem;
      }
    }
  }
  &.cardColor_transparent {
    .card-details-block {
    }
  }
  &.pull-quote {
    &.cardColor_spinDark {
      div,
      span,
      p,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: white;
      }
    }
    flex-direction: row;
    align-items: center;
    .card-details-block {
      flex-grow: 0;
    }
    .flexSpacer {
      display: none;
    }
    blockquote {
      div,
      span,
      p,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-size: 1.5625rem;
        line-height: 2.5rem;
      }
    }
    blockquote {
      margin-bottom: 2.375rem;
    }
    p {
      font-size: 0.8125rem;
      line-height: 1.25rem;
    }
  }
  /* HOVER ANIMATION */
  .hvr-bounce-to-right {
    transform: perspective(1px) translateZ(0);
    position: relative;
    transition-duration: 0.5s;
    overflow: hidden;
  }
  .hvr-bounce-to-right:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${themeGet('colors.spinSecondaryOne')};

    width: 0;
    /* border-radius: 36.5px; */
    /* transform: scaleX(0);
    transform-origin: 0 50%;*/
    transition-property: width;
    transition-duration: 0.5s;
    transition-timing-function: ease-out;
  }
  .hvr-bounce-to-right:hover:before,
  .hvr-bounce-to-right:focus:before,
  .hvr-bounce-to-right:active:before {
    /* transform: scaleX(1); */
    width: 100%;
    transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  }

  /*
  a {
    font-size: 1em;
    font-weight: 600;
    line-height: inherit;
    letter-spacing: inherit;
    color: ${themeGet('spinTextDark')};
    text-decoration: none;
    background-color: white;
    border-radius: 36.5px;
    padding: 17px 40px;
    display: block;
    text-align: center;
  }
  */

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0; /* modified based on card design on homepage */
    margin-bottom: 0.5rem;
    ${themeGet('typeStyles.card.imageAbove.heading')}

    @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
      ${themeGet('typeStyles.card.imageAbove.mobile.heading')}
    }
  }
  p {
    &.dateTime {
      margin-bottom: 0.3125rem;
    }
  }
  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    p {
      &.dateTime {
        ${themeGet('typeStyles.card.imageAbove.mobile.subheading')}
        margin-bottom:0.5rem;
      }
    }
  }

  div {
    margin-bottom: 0;

    a {
      @media only screen and (max-width: 880px) {
        font-size: 0.9rem;
        line-height: 1rem;
      }
    }
  }

  @media only screen and (min-width: ${themeGet('breakpoints.md')}) {
    width: 100%;
    ${(props) => (props.columns != 1 ? 'max-width: 775px;' : '')}
  }
`;

export const Info = styled.div`
  padding: 2rem 2rem 2.5rem 2rem; /* Modified to match the homepage design -  Back away from this line of code if you want to see the sunrise again */
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .card-details {
    flex-grow: 0;
    flex-shrink: 0;
    margin-bottom: 2rem;
  }
  .cardLinkBlock {
    display: flex;
    flex-direction: column-reverse;
    & > a {
      margin: 0px;
      margin-top: 10px;
      margin-bottom: 0;
      width: fit-content;
    }
  }
  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    padding: 1.25rem 1rem 2rem 1rem;
  }
`;
export const AbsoluteRatio = styled.div`
  width: 100%;
  height: 0;
  padding-top: 56%;
  position: relative;
  & > div,
  & > img {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
export const StyledImage = styled.img`
  display: block;
  margin: 26px auto;
`;
