import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const StyledFilter = styled.div`
  color: ${themeGet('colors.spinTextDark')};
  max-width: ${themeGet('document.maxWidth')};
  margin: 20px auto;
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 0;
  grid-row-gap: 0;
  grid-auto-flow: row;

  display: flex;
  flex-wrap: wrap;
  margin-left: 1rem;
  margin-right: 2rem;
  .filterButton {
    &.active {
      font-weight: 900;
    }
    display: block;
    white-space: normal;
    min-width: unset;
    padding: 0 1rem;
    height: unset;
    min-height: unset;
    margin-bottom: 0.825rem;
    font-size: 1rem;
    &:hover,
    &:visited,
    &.active {
      border-radius: 0;
    }
    &:first-child {
    }
    &:hover {
      /* font-weight: bold; */
    }
  }

  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    margin: 0 auto;
    max-width: Calc(
      100vw - 2rem
    ); /* added to allow scrolling on pages like press-release */
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    justify-content: space-between;
    padding-bottom: 0.825rem;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    &::-webkit-scrollbar:horizontal {
      height: 11px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 4px solid white; /* should match background, can't be transparent */
      background-color: ${themeGet('colors.spinDark')};
    }
    flex-wrap: nowrap;
    .filterButton {
      white-space: nowrap;
      min-width: unset;
      padding: 0 1rem;
      height: unset;
      min-height: unset;
      padding-bottom: 0.825rem;
      font-size: 1rem;
      text-align: center;
      &:first-child {
        margin-left: -1rem;
      }
      &:last-child {
        /* padding-right: 0; */
      }
    }
  }
  .brand-neutral {
    &:hover,
    &:focus {
      border-radius: 0;
    }
  }
`;

export const StyledFilterBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  margin-bottom: 2.375rem;
  &.one-column {
    .filter-block-filter {
      width: 100%;
    }
  }
  &.two-columns {
    .filter-block-filter {
      width: 70%;
    }
  }

  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    margin: 0 0 2.375rem;
    &.two-columns {
      .filter-block-filter {
        width: 100%;
      }
    }
    .filter-block-filter {
    }

    .filter-block-ctas {
      width: 100%;
      margin-top: 2.5rem;
      .styledLinkList {
        margin-left: 0px;
        margin: 0 auto;
        text-align: center;
      }
    }
  }
  &.filterBlock_rolodex {
    margin-bottom: 0;
    padding-bottom: 0;
    .filter-block-filter {
      .styledFilter {
        margin-bottom: 0;
        margin-left: 0rem;
        .filterButton {
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }
  &.filterBlock_media-section-3-deep {
    margin-bottom: 0;
    padding-bottom: 0;
    h3 {
      margin-bottom: 2.75rem;
    }
    @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
      .filterButton:first-child {
        margin-left: 0;
      }
    }
  }
  &.filterBlock_brand,
  &.filterBlock_leadership,
  &.filterBlock_cards,
  &.filterBlock_form {
    .styledFilter {
      margin-top: 0;
      margin-bottom: 0;
    }
    margin-top: 4.25rem;
    margin-bottom: 1rem;

    @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
      margin-top: 0;
    }
  }
  &.filterBlock_form {
    max-width: ${themeGet('article.narrow.maxWidth')};
    margin: 0 auto;
  }
  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    .filterButton {
      padding-top: 0.825rem;
      &:first-child {
        margin-left: 0;
      }
    }
  }
`;
export const GhostWrapper = styled.div`
  pointer-events: none;
  text-align: center;
  display: block;
  height: 0;
  visibility: hidden;
  overflow: hidden;
  font-weight: 900;
`;
