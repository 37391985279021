import React from 'react';
import PropTypes from 'prop-types';

import { StyledTextAreaField, ErrorMessage } from './styles';

/**
 * Represents an input field with label.
 * @param {string} name required
 * @param {string} label required
 * @param {string} type required
 * @param {boolean} isRequired required
 * @param {boolean} isInvalid required
 * @param {function} onChange required
 * @param {string} value required
 * @param {string} fieldErrorText required
 */
const TextAreaField = ({
  name,
  label,
  type,
  value,
  isRequired,
  onChange,
  isInvalid,
  fieldErrorText,
  placeholder,
}) => (
  <>
    <StyledTextAreaField htmlFor={name}>
      {label}
      {isRequired && `*`}
      <textarea
        id={name}
        name={name}
        value={value}
        required={isRequired}
        aria-required={isRequired}
        aria-invalid={isInvalid}
        onChange={onChange}
        className={isInvalid ? 'invalid' : ''}
        placeholder={placeholder}
      />
    </StyledTextAreaField>
    <ErrorMessage aria-live="polite">
      {isInvalid && fieldErrorText}
    </ErrorMessage>
  </>
);

TextAreaField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isRequired: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  isInvalid: PropTypes.bool.isRequired,
  fieldErrorText: PropTypes.string,
};

TextAreaField.defaultProps = {
  label: '',
  fieldErrorText: '',
};

export default TextAreaField;
