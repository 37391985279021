import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const StyledReleases = styled.section`
  display: -ms-grid;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-columns: ${(props) => `repeat(${props.columns}, 1fr)`};

  grid-gap: 50px;
  border-radius: 5px;
  margin-bottom: 15px;

  article {
    a,
    span {
      font-size: 1rem;
      line-height: 1.625rem;
      color: ${themeGet('colors.spinDark')};
      text-decoration: none;
    }
    p {
      font-size: 0.8125rem;
      line-height: 1.25rem;
    }
  }
  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    /* display: grid;*/
    grid-template-columns: auto;
    article {
      a,
      span {
        font-size: 0.875rem;
        line-height: 1.5rem;
      }
    }
  }
`;

export const StyledReleasesWrap = styled.div`
  width: 100%;
  h2 {
    margin-left: 2rem;
    font-size: 2.8125rem;
    line-height: 3.4375rem;
  }
  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-left: 1rem;
      padding-left: 0rem;
    }
    h1 {
      margin-left: 0;
    }
    h2 {
      font-size: 1.875rem;
      line-height: 2.1875rem;
    }
  }
`;

export const SubscribeSection = styled.section`
  h2{
  font-size: 2.8125rem;
  line-height: 3.4375rem;
  }
@media only screen and (max-width: ${themeGet('breakpoints.md')}) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top:0;
  }

  h2 {
    font-size: 1.875rem;
    line-height: 2.1875rem;
  }
`;
