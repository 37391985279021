import React,{ useState, useEffect }  from 'react';
import axios from 'axios';

import PropTypes from 'prop-types';
import moment from 'moment';

import ArticlePreview from '../ArticlePreview';

import { StyledArticleList } from './styles';
// {parseString} = require 'xml2js'

// async


const ArticlePreviewList = ({ components }) => {
  const [newList, setNewList] = useState(null);
  const [compareArticle, setCompareArticle] = useState({});



  useEffect(() => {
    if(!newList){
      getAllNewsFeedReleases();
    }
  }, [])



  const getAllNewsFeedReleases = () => {
    // const headers = { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' }

    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('Access-Control-Allow-Origin', '*');

    fetch(`https://spinmaster.mediaroom.com/api/newsfeed_releases/list.php?format=json&limit=1&offset=0`,{headers: headers})
      .then(response => {
        return response.json()
      }) // parse JSON from request
      .then(resultData => {
        setCompareArticle(resultData.release[0]);
        console.log('newest release = ', resultData.release[0] )
      }).catch((err)=>console.log('ERR > ',err))

    setNewList(true);
  };

  const getDate = (released)=>{

    let date =  moment.unix(released).local().format('MMMM DD, YYYY');

    if( date !== "Invalid date"){
      return date;
    }
    
  }

  

  return (
  <StyledArticleList>
    {compareArticle && components.length > 0 && compareArticle.id !== components[0].id && (
      <ArticlePreview
        key={compareArticle.id}
        id={compareArticle.id}
        title={compareArticle.headline}
        description={getDate(compareArticle.released)}
        className="articlePreview"
      />
    )}

    {components.map(({ id, headline, released }) => {
      { if(headline) 
        return (
          <ArticlePreview
            key={id}
            id={id}
            title={headline}
            description={getDate(released)}
            className="articlePreview"
          />
        );
      }
    })}
  </StyledArticleList>
)};

ArticlePreviewList.propTypes = {
  components: PropTypes.array,
};

ArticlePreviewList.defaultProps = {
  components: [],
};

export default ArticlePreviewList;
