import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const SectionWrapper = styled.section`
  .styledLinkList {
    margin-top: 0;
  }
  &.reports {
    padding: 0;
  }
  section.reports:last-child {
    margin-bottom: 1.875rem;
  }
`;
