import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { themeGet } from '@styled-system/theme-get';
import _ from 'lodash';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import RichText from '../../components/core/RichText';

import { getSrc } from 'gatsby-plugin-image';

import {
  SliderCarouselWrapper,
  SliderFeatureImage,
  SliderHeading,
  SliderSubHeading,
  SliderContainer,
  SliderTextContainer,
  SliderImageContainer,
  DescriptionWrapper,
  CoreBanner,
} from './styles';
import { isTypeSystemDefinitionNode } from 'graphql';

const themeColors = [
  'spinSecondaryTwo',
  'spinSecondaryThree',
  'spinSecondaryOne',
  'spinAccentOne',
  'spinAccentTwo',
];
const headingLevel = 2;

const NextArrow = ({ onClick }) => {
  return (
    <a onClick={onClick} className="icon-round-caro next caro-button">
      <div className="caro-button-n"></div>
    </a>
  );
};

const PrevArrow = ({ onClick }) => {
  return (
    <a onClick={onClick} className="icon-round-caro prev caro-button ">
      <div className="caro-button-p"></div>
    </a>
  );
};

const SliderCarousel = ({
  contentType,
  components,
  columns,
  horizontalScroll,
  ctaReference,
  imagePosition,
  headingLevel,
}) => {
  console.log('Second this place');
  console.log('The components are', components);

  const settings = {
    customPaging: () => {
      return (
        <a>
          <div className="carousel__circle"></div>
        </a>
      );
    },
    dots: true,
    // dotsClass: "carousel__dots",
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    speed: 2000,
    autoplay: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  return (
    <SliderCarouselWrapper>
      <Slider {...settings} className={`Carousel`}>
        {components.map((item, index) => {
          const image = item.bkgImage ? item.bkgImage : item.image;
          const bkMobileImage = item.bkMobileImage
            ? item.bkMobileImage
            : item.image;
          const richText = item.description
            ? item.description
            : item.richTextEditor;
          const title = item.title ? item.title : item.heading;
          const mappedVideo = {
            file: {
              contentType: 'video/youtube',
              urlSharp: { publicURL: item.videoCodeUrl },
            },
          };
          return (
            <CoreBanner
              backgroundColour={themeColors[0]}
              heading={title}
              richTextEditor={richText}
              bkgImage={
                item.mediaType === 'Video' && { mappedVideo }
                  ? mappedVideo
                  : image || null
              }
              bkgImageMobile={bkMobileImage}
              bannerCtaReference={ctaReference || null}
              contentPosition={index % 2 === 0 ? 'Left' : 'Right'}
              controls
              playing={false}
              className="coreBanner scalingHeight "
              headingLevel={headingLevel + 2}
            />
          );
        })}
      </Slider>
    </SliderCarouselWrapper>
  );
};

SliderCarousel.propTypes = {
  images: PropTypes.array,
  links: PropTypes.array,
  featureEnabled: PropTypes.bool,
  columns: PropTypes.number,
  resolverType: PropTypes.string,
  components: PropTypes.array,
  Tag: PropTypes.string.isRequired,
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
};
SliderCarousel.defaultProps = {
  images: null,
  links: null,
  featureEnabled: false,
  columns: null,
  resolverType: null,
  components: null,
};

export default SliderCarousel;
