import React from 'react';
import PropTypes from 'prop-types';

import { StyledTable, TableWrapper } from './styles';

/**
 * Represents Table.
 * @param {string} caption required - title of the table (used for accessibility, invisible)
 * @param {array} headerLabels required
 * @param {array} items required
 * @param {string} style [optional="topHeader"] - determines where table header is positioned - on the left side or on the top.
 */
const Table = ({ caption, headerLabels, items, style }) => {
  const headerCells = headerLabels.map((label) => (
    <th key={label} scope="col">
      {label}
    </th>
  ));
  const rows = items.map((row) => (
    <tr
      key={`id_tr_${Math.round(Math.random() * 1000)}${new Date().getTime()}`}
    >
      {row.map((column) => {
        return (
          <td
            key={`id_td_${Math.round(
              Math.random() * 1000
            )}${new Date().getTime()}`}
          >
            {column}
          </td>
        );
      })}
    </tr>
  ));

  const table = (
    <StyledTable aria-label={caption || ''} className={style}>
      <thead>
        <tr>{headerCells}</tr>
      </thead>
      <tbody>{rows}</tbody>
    </StyledTable>
  );

  return <TableWrapper>{table}</TableWrapper>;
};

Table.propTypes = {
  caption: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  headerLabels: PropTypes.array.isRequired,
  style: PropTypes.string,
};

Table.defaultProps = {
  style: 'topHeader',
};

export default Table;
