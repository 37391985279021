import React from 'react';
import PropTypes from 'prop-types';

import LinkGroups from '../LinkGroups';
import LinkList from '../core/LinkList';
import { sortByOrder } from '../../utils/helpers';

import { SectionWrapper } from './styles';

const ReportsBlock = ({ components, ctaReference, headingLevel }) => {
  if (components && components.length) {
    const elements = components.map((yearReports, index) => {
      const { id, title } = yearReports;
      return (
        <LinkGroups
          className="reports"
          key={id}
          title={title}
          groupList={yearReports.yearReports}
          headingLevel={headingLevel}
        />
      );
    });
    return (
      <SectionWrapper className="sectionReports">
        <div>{elements}</div>
        {ctaReference && !!ctaReference.length && (
          <LinkList
            links={ctaReference}
            ctaStyle="solid-on-dark-bg hvr-bounce-to-right"
          />
        )}
      </SectionWrapper>
    );
  }
  return null;
};

ReportsBlock.propTypes = {
  components: PropTypes.array.isRequired,
  ctaReference: PropTypes.array,
  headingLevel: PropTypes.number.isRequired,
};

ReportsBlock.defaultProps = {
  ctaReference: [],
};

export default ReportsBlock;
