import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from 'react-autocomplete';

import { StyledAutocomplete, ErrorMessage, Label, Input } from './styles';

/**
 * Represents an input field with label.
 * @param {string} name required
 * @param {string} label required
 * @param {string} type required
 * @param {boolean} isRequired required
 * @param {boolean} isInvalid required
 * @param {function} onChange required
 * @param {string} value required
 * @param {fieldOptions} value required
 * @param {string} fieldErrorText required
 */
const AutocompleteField = ({
  name,
  label,
  type,
  value,
  isRequired,
  onChange,
  isInvalid,
  placeholder,
  fieldErrorText,
  fieldOptions,
}) => {
  const [selectedOption, setSelectedOption] = useState('');

  return (
    <>
      <Label htmlFor={name}>
        {label}
        {isRequired && `*`}
      </Label>
      <Autocomplete
        getItemValue={(item) => item.label}
        name={name}
        id={name}
        className="autocomplete"
        items={
          fieldOptions.split('\n').map((val) => ({
            label: val.split(',')[0],
            value: val.split(',')[1],
          })) || []
        }
        required={isRequired}
        wrapperProps={{
          style: {
            width: '100%',
            display: 'block',
            position: 'relative',
          },
        }}
        renderInput={(props) => (
          <Input {...props} required={isRequired} className="autocomplete" />
        )}
        renderMenu={(items, value, style) => {
          return <StyledAutocomplete children={items} />;
        }}
        renderItem={(item, isHighlighted) => (
          <div
            style={{
              background: isHighlighted ? 'lightgray' : 'white',
            }}
          >
            {item.label}
          </div>
        )}
        value={selectedOption}
        onChange={(e) => setSelectedOption(e.target.value)}
        onSelect={(value, x) => {
          onChange({ target: { name, value: x.value } });
          setSelectedOption(value);
        }}
        inputProps={{ placeholder }}
      />
      <ErrorMessage aria-live="polite">
        {isInvalid && fieldErrorText}
      </ErrorMessage>
    </>
  );
};

AutocompleteField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string,
  isRequired: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  isInvalid: PropTypes.bool.isRequired,
  fieldErrorText: PropTypes.string,
  fieldOptions: PropTypes.string.isRequired,
};

AutocompleteField.defaultProps = {
  fieldErrorText: '',
  value: '',
};

export default AutocompleteField;
