import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import RolodexCard from '../RolodexCard';
import { CardsWrapper } from '../../CardsBlock/styles';

const rolodexTypes = {
  ROLODEX: 'Rolodex',
  ROLODEX_WITH_TITLE: 'Rolodex with Title',
};

/**
 * Represents a list of Card components.
 * @param {array} items required list of Profile objects
 * @param {array} ctaReference [optional=[]] list of links/buttons
 * @param {number} columns [optional=3] - how many items should fit on screen
 * @param {boolean} horizontalScroll [optional=true] - should scroll using horizontal scroll
 */
const Rolodex = ({
  components = items,
  columns,
  horizontalScroll,
  contentType,
  headingLevel,
}) => {
  const showTitle = contentType === rolodexTypes.ROLODEX_WITH_TITLE;
  columns = columns.length > 1? columns.slice(0, 1) : columns;
  const rolodexItemIndices = [];
  if (components.length) {
    components.sort((a, b) => {
      let comparison = 1;
      if ((a.title || a.helpshiftRepoName) > (b.title || b.helpshiftRepoName)) {
        comparison = 1;
      } else if ((a.title || a.helpshiftRepoName) < (b.title || b.helpshiftRepoName)) {
        comparison = -1;
      }
      return comparison;
    });
    const cardElements = components.map((component, index) => {
      const {
        helpshiftRepoName,
        helpshiftRepoLink,
        helpshiftRepoImage,
        brandLogo,
        tags,
        ctaLinks,
        title,
        iconImage
      } = component;
      if (title || helpshiftRepoName) {
        let componentTitle = title ? title : helpshiftRepoName;
        let rolodexItemLetter = '';
        if (rolodexItemIndices.indexOf(componentTitle.substr(0, 1)) === -1) {
          rolodexItemIndices.push(componentTitle.substr(0, 1));
          rolodexItemLetter = componentTitle.substr(0, 1);
        }

        let imageSrc = '';
        let imageSrcSharp = {};
        if (brandLogo && brandLogo.imageLogo) {
          imageSrc = brandLogo.imageLogo.file.url;
          imageSrcSharp = brandLogo.imageLogo.gatsbyImage;
        } else if (helpshiftRepoImage) {
          imageSrc = helpshiftRepoImage.file.url;
          imageSrcSharp = helpshiftRepoImage.gatsbyImage;
        } else if (iconImage) {
          imageSrc = iconImage.file.url;
          imageSrcSharp = iconImage.gatsbyImage;
        }
        return (
          <RolodexCard
            key={`${componentTitle}-${index}-${Math.random()
              .toString(36)
              .substring(7)}`}
            title={componentTitle}
            rolodexIndex={rolodexItemLetter}
            linkURL={helpshiftRepoLink}
            links={ctaLinks}
            image={imageSrc}
            imageSharp={imageSrcSharp}
            className="rolodexCard"
            showTitle={showTitle}
            headingLevel={headingLevel}
          />
        );
      }
    });
    const cssStyle = horizontalScroll ? 'rolodex horizontal-scroll' : 'rolodex';
    return (
      <CardsWrapper className={cssStyle} columns={columns}>
        {cardElements}
      </CardsWrapper>
    );
  }
  return null;
};

Rolodex.propTypes = {
  components: PropTypes.array.isRequired,
  columns: PropTypes.string,
  horizontalScroll: PropTypes.bool,
  ctaReference: PropTypes.array,
};

Rolodex.defaultProps = {
  columns: 3,
  horizontalScroll: false,
  ctaReference: [],
};

export default Rolodex;
