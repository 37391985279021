import React, { useState } from 'react';
import PropTypes from 'prop-types';

import LinkGroup from '../core/LinkGroup';

import { Container, ActiveSection, MobileMenuBackground } from './styles.js';

const SubNavigation = ({ components, title, ariaLabel }) => {
  const [isOpen, setIsOpen] = useState(false);
  if (!components || components.length === 0) {
    return null;
  }

  return (
    <Container open={isOpen} className="subnavigation">
      <ActiveSection
        open={isOpen}
        role="button"
        className="titleCurrentPage isSticky"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="carat" />
        <p>{title || components[0].textLink}</p>
      </ActiveSection>
      <LinkGroup
        key={title}
        title={title}
        ariaLabel={ariaLabel}
        titleVisible={false}
        links={components}
        displayStyle="inline"
        className="subnavigation"
      />
      <MobileMenuBackground open={isOpen} />
    </Container>
  );
};

SubNavigation.propTypes = {
  components: PropTypes.array, // not required - some pages don't have subnavs
  title: PropTypes.string,
  ariaLabel: PropTypes.string, // not required - some pages don't have subnav
};

SubNavigation.defaultProps = {
  components: [],
  title: null,
  ariaLabel: null,
};

export default SubNavigation;
