import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const StyledCard = styled.div`
  width: 100%;
  overflow: hidden;
  max-width: 100%;
  background-color: transparent;
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid #d8d8d8;
  .fixed-width {
    width: 70px;
    margin-right: 20px;
    &.rolodexIndex .rolodex-card-index {
      margin: 0;
      font-family: ${themeGet('fonts.rubik')};
      color: rgb(0, 22, 85);
      font-weight: 900;
      font-size: 1.875rem;
      line-height: 2.5rem;
      letter-spacing: 0;
    }

    @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
      margin-right: 0;
      padding: 0.5rem 0;
      &.rolodexIndex {
        margin-left: 0;
        text-align: left;
        width: 60px;
        max-width: 60px;
        flex: 0 0 auto;
      }
      &.rolodexIndex .rolodex-card-index {
        font-size: 1.3125rem;
        line-height: 1.875rem;
      }
    }
  }

  img {
    border-radius: 5px 5px 0 0;
    width: 100%;
  }

  .rolodex-card-title {
    margin: 0;
    font-family: ${themeGet('fonts.sans')};
    color: rgb(0, 22, 85);
    font-weight: 600;
    font-size: 1.3125rem;
    line-height: 2.125rem;
    letter-spacing: 0px;
    @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
      font-size: 1rem;
      line-height: 1.625rem;
    }
  }
`;

/* card-details-block */
export const Info = styled.div`
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
    .rolodexIndexWrapper,
    .cardDetails {
      margin-left: 20px;
      flex: 1;
      .absoluteRatio {
        flex: 1;
        margin: 0 1rem;
        min-width: 0.5rem;
        display: none;
      }
      .externalStyledLink {
        img {
          min-width: 21px;
          margin-right: 1rem;
        }
      }
    }
  }
`;

export const AbsoluteRatio = styled.div`
  width: 70px;
  height: 60px;
  margin: 10px 0;
  position: relative;
  & > div {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    width: 100% !important;
    height: 100% !important;
    text-align: center;
    img {
      object-fit: contain !important;
    }
  }
`;

export const RolodexIndex = styled.div`
  max-width: 50px;
`;
/* rolodexIndexWrapper */
export const RightSide = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    align-items: center;
  }
`;

export const CardDetails = styled.div`
  display: flex;
  justify-content: space-between;
  a {
    margin: 0 92px 0 0;
    height: 100%;
    margin-bottom: 0;
    span {
      display: flex;
      align-items: center;
      height: 100%;
      .h4 {
        padding-right: 2rem;
        color: ${themeGet('colors.spinDark')};
        font-family: ${themeGet('fonts.sans')};
        font-size: 0.875rem;
        font-weight: normal;
        line-height: 1.5rem;
      }
      img {
        width: 21px;
        top: unset;
      }
    }
  }
  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    text-align: right;
    padding-left: 0;
    box-sizing: border-box;
    display: inline-block;
    margin-bottom: 1rem;

    a {
      margin-right: 0;
      margin-bottom: 0.5rem;
      display: inline-block;
      span {
        .h4 {
          width: auto;
          text-align: right;
          padding-right: 1rem;
        }
      }
    }
  }
  @media only screen and (max-width: 620px) {
    margin: 0;
  }
`;
