import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const AccordionWrapper = styled.section`
  display: block;
  background-color: ${themeGet('colors.spinSecondaryFour')};
  border: 1px solid ${themeGet('colors.formInputBorder')};
  width: 100%;
  padding: 3.125rem 24px 1rem 2rem; /* Padding specified in Customer Support design */
  border-radius: 5px;

  color: ${themeGet(
    'colors.spinDark',
  )}; /*Specified in Customer Support Design */
  .arrowUp {
    display: block;
    transform: scaleY(-1);
  }
  .arrowDown {
    display: none;
  }
  .accordion-title {
    margin-top: 0;
    margin-bottom: 2.6875rem; /* Padding specified in Customer Support design */
    font-size: 2.8125rem;
    line-height: 3.4375rem;
  }

  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    .accordion-title {
      margin-bottom: 1rem;
    }
  }
`;
export const AccordionItem = styled.div`
  background-color: white;
  border-radius: 5px;
  padding: 1.5rem 1.5rem 2rem 1.5rem;
  margin: 2rem 0;

  &.accordionItem .details {
    overflow: hidden;
    max-height: 10000px;
    transition: all 0.5s ease-out;
  }
  &.accordionItem.collapsed .details {
    max-height: 0;
  }

  &.active {
    margin-bottom: 3.375rem;
    .arrowUp {
      display: none;
    }
    .arrowDown {
      display: block;
    }
  }

  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    padding: 1rem 1rem 1.25rem 1rem;
  }
`;
export const AccordionHandle = styled.div`
  width: 5.75rem;
  height: 1.125rem;
  .handleIndicator {
    width: 1.125rem;
    height: 100%;
    object-fit: contain;
    opacity: 0.75;
    transition: all 0.5s ease-out;
  }
  &:hover {
    .arrowDown {
      opacity: 1;
    }
  }
  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    max-width: 40px;
  }
`;
export const AccordionDetails = styled.div`
  margin-right: 4.625rem;
  margin-top: -0.5rem;
  width: Calc(100% - 5.75rem); /*Specified in Customer Support Design */
  margin-left: 5.75rem;
  box-sizing: border-box;
  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
  .card-details-block {
    padding: 0;
    min-height: unset;
    .specialValue {
      margin-top: 1.125rem;
      table {
        th,
        td {
          border-bottom: none;
          padding-left: 0;
          padding-top: 0;
        }
        td {
          padding: 0;
        }
      }

      @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
        margin-left: 0;
        table {
          width: 100%;
          tr,
          td,
          th {
            display: flex;
            flex-direction: column;
          }
          td:first-child {
            font-weight: 600;
            &:empty {
              display: none;
            }
          }
          tr {
            margin-bottom: 1rem;
            &:last-child {
              margin-bottom: 0;
            }
          }
          th {
            display: none;
          }
        }
      }
    }
    .card-details {
      margin-bottom: 0;
    }
  }
`;

export const AccordionTop = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  .accordion-item-title {
    font-family: 'Open Sans';
    font-size: 1.3125rem;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1.9375rem;
    color: ${themeGet(
      'colors.spinDark',
    )}; /*Specified in Customer Support Design */
    margin: 0;
  }
  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    .accordion-item-title {
      font-size: 1rem;
      line-height: 1.625rem;
    }
  }
`;
