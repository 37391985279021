import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const StyledIframe = styled.iframe`
  width: 260px;
  height: 1030px;
  display: block;
  margin: 60px auto;
  @media only screen and (min-width: ${themeGet('breakpoints.xs')}) {
    width: 320px;
  }
  @media only screen and (min-width: ${themeGet('breakpoints.sm')}) {
    width: 390px;
    height: 840px;
  }
  @media only screen and (min-width: 600px) {
    width: 580px;
  }
  @media only screen and (min-width: ${themeGet('breakpoints.md')}) {
    width: 850px;
    height: 670px;
  }
`;
