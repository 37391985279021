import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const Container = styled.div`
  top: 70px;
  transition: all 0s 0s linear;
  .subnavigation {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
    position: absolute;
    top: -12px;
    z-index: 100;
    width: Calc(100%);
    a {
      padding: 0.75rem 0.5rem 0.1875rem 0.5rem;
      margin-bottom: 0.75rem;
      font-size: 0.75rem;
      border-bottom: 3px solid transparent;
      color: ${themeGet('colors.spinTextDark')};
      text-align: center;
      display: inline-block;
      line-height: 1em;

      @media only screen and (max-width: ${themeGet('breakpoints.lg')}) {
        padding: 0.75rem 0 0.1875rem 0;
      }
      &.active,
      &:hover {
        background-color: transparent;
        border-bottom: 3px solid ${themeGet('colors.spinSecondaryOne')};

        span {
          font-weight: 600;
        }
      }

      span {
        font-family: 'Rubik', sans-serif;
        font-weight: 500;
        color: ${themeGet('spinTextDark')};
      }
    }
    ul {
      margin-right: -0.5rem;
      li {
        padding-bottom: 0;
        :last-child {
          padding-right: 0;
        }
        a {
          margin-bottom: 0;
        }
      }
    }
    /* Custom Spacing for subnav to avoid wrapping */
    @media only screen and (max-width: 900px) {
      ul {
        li {
          padding-right: 0.825rem;
        }
      }
    }
  }
  width: calc(100vw - 5rem);

  @media only screen and (max-width: ${themeGet('breakpoints.lg')}) {
    width: Calc(100vw - 5rem);

    nav.subnavigation {
      ul {
        width: Calc(100vw - 15rem);
        right: unset;
        text-align: right;
        justify-content: flex-end;
        li {
          /* padding: 0; */
        }
      }
    }
  }

  max-width: ${themeGet('document.maxWidth')};
  z-index: 90;
  position: fixed;
  @media only screen and (max-width: ${themeGet('breakpoints.lg')}) {
    right: unset;
    height: 50px;
    margin-left: -16px;
    width: 100vw;
    background: white;

    nav.subnavigation {
      /* visibility: hidden; */
      margin-right: 0;
      width: 100vw;
      padding: 0 1rem;
      position: absolute;
      background: white;
      transition: all 0.4s cubic-bezier(0.45, 0, 0.55, 1);
      top: ${({ open }) => (open ? '50px' : '-100vh')};
      display: block;
      height: auto;
      z-index: 96;
      padding-bottom: 20px;
      ul {
        flex-direction: column;
        width: 100%;
        li {
          text-align: left;
          height: 2rem;
        }
      }
      a {
        padding: 0;
        font-size: 1rem;
        height: 2.25rem;
        line-height: 2.5rem;
        font-family: 'Rubik', sans;
      }
    }
  }

  @media only screen and (max-width: ${themeGet('breakpoints.sm')}) {
    .titleCurrentPage {
      padding-left: 2rem;
      display: flex;
      align-content: center;
      align-items: center;
      p {
        max-width: 100px;
      }
    }
  }
`;

export const ActiveSection = styled.div`
  display: none;
  @media only screen and (max-width: ${themeGet('breakpoints.lg')}) {
    font-size: 1rem;
    display: block;
    padding-left: 2.125rem;
    cursor: pointer;
    height: 100%;
    line-height: 50px;

    p {
      color: ${themeGet('colors.spinTextDark')};
      margin: 0;
      padding: 0;
      font-size: 1rem;
      display: inline-block;
      font-weight: 600;
    }

    .carat {
      position: relative;
      display: inline-block;
      height: 6px;
      margin-right: 6px;
      top: ${(props) => (props.open ? -6 : 0)}px;
      /* transform: rotate(${(props) => (props.open ? 90 : -90)}deg); */
      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 10px;
        width: 10px;
        height: 2px;
        background: ${themeGet('colors.spinTextDark')};
        /* transform: rotate(135deg); */
        transform: rotate(${(props) => (props.open ? 225 : 135)}deg);
        transform-origin: right;
      }
      &:after {
        transform: rotate(${(props) => (props.open ? -45 : 45)}deg);
        /* transform: rotate(45deg); */
      }
    }
  }
`;

export const MobileMenuBackground = styled.div`
  position: fixed;
  width: 100vw;
  height: ${(props) => (props.open ? 'calc(100vh - 60px)' : 0)};
  background: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 126px;
  transition: opacity 0.4s ease-out;
  /* display: ${(props) => (props.open ? 'visible' : 'none')}; */
  opacity: ${(props) => (props.open ? 1 : 0)};

  @media only screen and (min-width: ${themeGet('breakpoints.lg')}) {
    display: none;
  }
`;
