import React from 'react';
import PropTypes from 'prop-types';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import ReactMarkdown from 'react-markdown';

import Image from '../core/Image';
import { sortBy } from '../../utils/helpers';

import {
  StyledTimeline,
  ContentWrapper,
  ImageWrapper,
  DateIconWrapper,
} from './styles';

const Timeline = ({ listComponents }) => {
  const colors = ['#3E9AFF', '#7BE6CF', '#FFE455', '#FF55C5'];
  sortBy(listComponents, 'dateUpdated');
  return (
    <StyledTimeline>
      <VerticalTimeline layout="1-column">
        {listComponents &&
          listComponents.map((item, idx) => (
            <VerticalTimelineElement
              key={item.id}
              className={`vertical-timeline-element--work color-${idx % 4}`}
              contentStyle={{
                background: 'transparent',
              }}
              contentArrowStyle={{
                margin: '5px 0 0 2px',
                borderRight: `7px solid ${colors[idx % 4]}`,
              }}
              date={new Date(item.dateUpdated).toDateString()}
              dateClassName="timeline-date"
              iconStyle={{
                background: `${colors[idx % 4]}`,
                width: 50,
                height: 50,
                boxShadow: `0 0 0 4px ${
                  colors[idx % 4]
                }, inset 0 2px 0 rgba(0,0,0,.08), 0 3px 0 4px rgba(0,0,0,.05)`,
              }}
              icon={
                <DateIconWrapper>
                  {new Date(item.dateUpdated).getFullYear() + 1}
                </DateIconWrapper>
              }
            >
              <ContentWrapper>
                <h3 className="vertical-timeline-element-title">
                  {item.title}
                </h3>
                <p>
                  <ReactMarkdown>{item.description.description}</ReactMarkdown>
                </p>
              </ContentWrapper>
              <ImageWrapper>
                {item.image && (
                  <Image alt={item.title} imageSharp={item.image} />
                )}
              </ImageWrapper>
            </VerticalTimelineElement>
          ))}
      </VerticalTimeline>
    </StyledTimeline>
  );
};

Timeline.propTypes = {
  items: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
};

Timeline.propTypes = {
  items: [],
};

export default Timeline;
