import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Button from '../Button';

import { StyledFilter, GhostWrapper } from './styles';

/**
 * Represents a Filter.
 * @param {string} showAllText required - 'Show all' text.
 * @param {array} options required - options for the filter:
 * @param {string} title required - The title of the section.
 * @example [ "2020", "2019" ]
 */
const Filter = ({ options, onClick: handler, showAllText = 'Show All' }) => {
  const [selected, select] = useState(showAllText);

  function handleClick(e) {
    const selectedValue = e.target.id;
    updateSelectedFilter(selectedValue);
  }
  const updateSelectedFilter = (id) => {
    select(id);
    handler(id);
  };
  // Date Filter Styles
  function getCssClass(category) {
    return selected === category ? 'active brand-neutral' : 'brand-neutral';
  }

  let firstId;
  const optionsElements = options.map((option, index) => {
    if (!option) {
      /* eslint-disable */
      console.log(
        'Button has no parameters. Check CMS source: skipping render'
      );
      /* eslint-enable */
      return null;
    }
    if (!showAllText && !firstId) {
      firstId = option;
    }

    const setDefaultFilter = true;
    if (setDefaultFilter) {
      useEffect(() => {
        if (showAllText) {
          updateSelectedFilter('Show All');
        } else {
          updateSelectedFilter(options[0]);
        }
      }, []);
    }
    return (
      <Button
        title={option}
        key={`${option}`}
        id={option}
        onClick={handleClick}
        className={`ind_${index} filterButton ${getCssClass(option)}`}
        disabled={false}
      >
        <GhostWrapper aria-hidden="true">{option}</GhostWrapper>
        {option}
      </Button>
    );
  });

  const showAllButton =
    showAllText !== '' ? (
      <Button
        title={showAllText}
        key={showAllText}
        id={showAllText}
        onClick={handleClick}
        className={`filterButton ${getCssClass(showAllText)}`}
        disabled={false}
      >
        <GhostWrapper>{showAllText}</GhostWrapper>
        {showAllText}
      </Button>
    ) : null;
  return (
    <StyledFilter className="styledFilter">
      {showAllButton}
      {optionsElements}
    </StyledFilter>
  );
};

Filter.propTypes = {
  onClick: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  showAllText: PropTypes.string.isRequired,
};

export default Filter;
