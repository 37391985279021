import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const StyledProfile = styled.article`
  display: block;
  max-width: 890px;
  margin: auto;
  margin-top: 5.875rem;
  & article {
    margin-top: 0;
  }
  h2 {
    font-family: ${themeGet('fonts.sans')};
    font-size: 1.3125rem;
    font-weight: 600;
    line-height: 1.9375rem;
    margin: 0 0 2.5rem;
  }
  img {
    width: 100%;
    border-radius: 5px 5px 0 0;
  }
  .styledLinkList {
    margin-left: 0;
  }

  @media only screen and (max-width: ${themeGet('breakpoints.md')}) {
    margin-top: 8.5625rem;
  }
`;
