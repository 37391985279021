import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { getUserLangKey } from 'ptz-i18n';

import { removeTrailingSlash } from '../../../utils/gatsby-node-helpers';
import SEO from '../SEO';

const Redirect = ({ locales, location, getLangPreference, origin }) => {
  useEffect(() => {
    const defaultLangKey =
      locales && locales.locale
        ? locales.locale.find((localeData) => localeData.default).code
        : 'en-US';

    // * Array of all langs for the website
    const langs =
      locales && locales.locale
        ? locales.locale.map((langData) => langData.code)
        : [{ code: 'en-US' }];

    // * Check if it should redirect to defaultlang, or get a user's pref lang
    const langKey = getLangPreference
      ? getUserLangKey(langs, defaultLangKey)
      : defaultLangKey;

    // * Need to match returned lang with correct path
    const prefix =
      locales && locales.locale
        ? locales.locale.find((localeData) => localeData.code === langKey)
        : langs.find((lang) => lang === langKey)
        ? langs.find((lang) => lang === langKey)
        : { code: 'en-US' };

    // * The Url to be navigated to
    const url =
      location && location !== '/home' && location !== '/'
        ? location.indexOf(prefix.code) === -1
          ? `/${prefix.code}${location}`
          : location
        : `/${prefix.code}`;

    navigate(removeTrailingSlash(url));
  });
  return <SEO location={origin} />;
};

Redirect.defaultProps = {
  getLangPreference: true,
};

export default Redirect;
